import { DependencyList, useCallback, useEffect, useState } from "react";

export function useAsync<T>(
  callback: () => Promise<T>,
  dependencies: DependencyList = [],
  immediate: boolean = true
) {
  const [status, setStatus] = useState<
    "idle" | "pending" | "success" | "error"
  >("idle");
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState(null);

  const callbackMemoized = useCallback(() => {
    setStatus("pending");
    setError(null);
    setValue(null);
    callback()
      .then((response) => {
        setStatus("success");
        setValue(response);
        setError(null);
      })
      .catch((error) => {
        setStatus("error");
        setValue(null);
        setError(error);
      });
  }, dependencies);

  useEffect(() => {
    if (immediate) {
      callbackMemoized();
    }
  }, [callbackMemoized, immediate]);

  return { status, error, value, refetch: callbackMemoized };
}
