import { Alert, Link } from "@mui/material";
import { useEffect, useState } from "react";

import { More } from "@mui/icons-material";
import { Container, Data, Root, Value } from "./AdditionalData.style";
import Theme from "theme/theme";
import { DATA_BY_KEY } from "./constants";
import { PopupKeys } from "components/subscription/interface";

export const AdditionalData = ({ data }: { data: Record<string, string> }) => {
  const [isCopied, setIsCopied] = useState<string>();

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(undefined);
      }, 1500);
    }
  }, [isCopied]);

  const copiedValues = ["order_id", "shopify_customer_id", "customer_id"];
  const popupValues = ["customer_id", "shopify_customer_id"];

  const getUrl = (columnKey: string, value: string) =>
    `https://${DATA_BY_KEY[columnKey as PopupKeys]}/${value}`;


  return (
    <Root>
      {Object.entries(data).map(([key, value], index) => (
        <Container key={index}>
          <Data>{[key]}:</Data>
          <Data
            onClick={() => {
              if (value && copiedValues.includes(key)) {
                setIsCopied(key);
                navigator.clipboard.writeText(value);
              }
            }}
          >
            <>
              {copiedValues.includes(key) && isCopied === key && (
                <Alert
                  sx={{
                    height: "2.5rem",
                    marginTop: "-2rem",
                    border: "unset",
                    fontWeight: 800,
                    position: "absolute",
                  }}
                  icon={false}
                  variant="outlined"
                  severity="info"
                >
                  Copied to clipboard
                </Alert>
              )}
              <Value>
                {[value]}
                {popupValues.includes(key) && (
                  <Link
                    rel="noopener"
                    target="_blank"
                    href={getUrl(key, value)}
                    underline="none"
                  >
                    <More
                      sx={{
                        color: Theme.palette.info.main,
                        fontSize: "1rem",
                      }}
                    />
                  </Link>
                )}
              </Value>
            </>
          </Data>
        </Container>
      ))}
    </Root>
  );
};
