import { useState } from "react";
//Common
import { FlexRow, FlexColumn } from "../../../common/containers";

//Interfaces
import { FullAccount } from "../../../../interfaces/models";
import { Device } from "../../../../interfaces/models/device";

//Utils
import { addDays } from "date-fns";

//Constants
import { ACCOUNT_FORM } from "./constants";

//Components
import { Form } from "../../../form";
import { Link } from "@mui/material";
import { DeviceLogsPopup } from "../deviceLogsPopup";
import { DateAndTime } from "components/dateAndTime";
import Table, { columnFields } from "../../../table/Table";

//Hooks
import { useAsync } from "../../../../hooks/useAsync";

//Services
import { devicesApi } from "../../../../services/api";
import { getDetails } from "utils";

export const UserDetails = ({ fullAccount }: { fullAccount: FullAccount }) => {
  const columns: columnFields[] = [
    {
      fieldName: "deviceId",
      columnName: "ID",
      costumeRenderColumn: ({ value }) => (
        <Link
          component="button"
          onClick={() => setSelectedDevice(value)}
          underline="hover"
        >
          {value}
        </Link>
      ),
    },
    {
      fieldName: "name",
      columnName: "Name",
    },
    {
      fieldName: "version",
      columnName: "Version",
    },
    {
      fieldName: "lastTimeUsed",
      columnName: "Last Seen",
      type: "date",
      costumeRenderColumn: ({ value }) => {
        const isTwoWeeks = new Date(value) < addDays(new Date(), -14);
        return (
          <FlexRow
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ...(isTwoWeeks && { color: "red", fontWeight: 800 }),
            }}
          >
            <DateAndTime value={value} row={null} />
          </FlexRow>
        );
      },
    },
  ];

  const [selectedDevice, setSelectedDevice] = useState<string>();

  const { value: devices, status: deviceStatus } = useAsync<Device[]>(
    () =>
      devicesApi.getUserDevices({
        userId: fullAccount.account._id,
      }),
    [fullAccount.account._id]
  );

  const details = getDetails<FullAccount>(ACCOUNT_FORM, fullAccount);

  return (
    <FlexColumn>
      <Form details={details} row={fullAccount} />
      {devices && (
        <Table<Device>
          title={"Devices"}
          list={devices}
          columns={columns}
          getItems={() => null}
          smallTable
          limit={10}
          total={devices.length}
          loading={deviceStatus === "pending"}
          withSearch={false}
        />
      )}
      {selectedDevice && (
        <DeviceLogsPopup
          open={!!selectedDevice}
          deviceId={selectedDevice}
          onClose={() => setSelectedDevice(undefined)}
        />
      )}
    </FlexColumn>
  );
};
