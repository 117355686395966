import axiosInstance from "../../config/axios";
import ServerRoutes from "./routes";
import { getParamsUrl, handleAxiosError } from "./utils/common";

const getUserDevices = async (params: { userId: string }) => {
  try {
    const url = getParamsUrl(ServerRoutes.devices.getUserDevices, params);
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message =
      "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    return {
      error: {
        message: message,
        status: err.status,
      },
    };
  }
};

const getDeviceTracer = async (
  deviceId: string,
  from?: Date,
  to?: Date
): Promise<{ createdAt: string; log: string }[]> => {
  try {
    const url = getParamsUrl(
      `${ServerRoutes.devices.getDeviceTracer}/${deviceId}/tracer`,
      { from, to }
    );
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error: any) {
    const err = handleAxiosError(error);
    let message =
      "Some error happen, please try again. If the error returns please contact support";
    switch (err.status) {
      case 500:
        message = "Server error. Please contact support";
        break;
      default:
        break;
    }
    console.error(message);
  }
  return [];
};

export const devicesApi = {
  getUserDevices,
  getDeviceTracer,
};
