import ServerRoutes from "./routes";
import { getParamsUrl, handleAxiosError } from "./utils/common";
import axiosInstance from "../../config/axios";
import { Order, SubscriptionData } from "interfaces/models";

export const shopifyApi = {
  getCustomerByParam: async (
    key: string,
    value: string
  ): Promise<SubscriptionData> => {
    try {
      let url = `${ServerRoutes.shopify.getCustomer}/?key=${key}&value=${
        value.charAt(0) === "#" ? value.slice(1) : value
      }`;
      const { data } = await axiosInstance.get(url);

      return data;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return {} as SubscriptionData;
    }
  },

  getOrders: async (
    onlyUnThreated: boolean,
    page: number
  ): Promise<{ data: Order[]; length: number }> => {
    try {
      let url = getParamsUrl(ServerRoutes.shopify.getOrders, {
        onlyUnThreated,
        limit: 10,
        page,
      });
      const { data } = await axiosInstance.get(url);

      return data;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return { data: [], length: 0 };
    }
  },

  createMultiOrders: async (orders: Order[]): Promise<Order> => {
    try {
      const { data } = await axiosInstance.post(
        ServerRoutes.shopify.createOrder,
        {
          orders: orders.map(
            ({
              address1,
              city,
              country,
              email,
              first_name,
              last_name,
              phone,
              province,
              zip,
              color,
              language,
              rowNumber,
            }) => ({
              address1,
              city,
              country,
              email,
              first_name,
              last_name,
              phone,
              province,
              zip,
              color,
              language,
              rowNumber,
            })
          ),
        }
      );

      return data;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return {} as Order;
    }
  },
};
