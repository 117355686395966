import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import FormInput from "components/ui/inputs/FormInput";
import { ChangeEvent, useState } from "react";

export const InputPicker = ({
  value,
  onChange,
  reset,
  options,
  initialState,
  label,
}: {
  value?: string;
  onChange: (key: string, value: string) => void;
  reset: () => void;
  options: { label: string; value: string }[];
  initialState?: string;
  label: string;
}) => {
  const [type, setType] = useState(initialState || options[0].value);

  return (
    <div>
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select
          value={type}
          label={label}
          onChange={({ target: { value } }) => {
            reset();
            setType(value);
          }}
          sx={{ height: "2.5rem", width: "15rem" }}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormInput
        value={value || ""}
        style={{
          marginLeft: "2.5rem",
          width: "15rem",
          height: "2rem",
        }}
        onChange={
          onChange
            ? ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
                onChange(type, value)
            : undefined
        }
      />
    </div>
  );
};
