import React, { useCallback } from "react";
import Table, { columnFields } from "../table/Table";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATHS } from "../../common/constants";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchLibraryStart } from "../../services/features/images-library/library-slice";
import {
  IGetLibraryParams,
  librarySortOptions,
} from "../../interfaces/api/library";
import { LibraryImage } from "../../interfaces/models/library-img";

const columns: columnFields[] = [
  {
    fieldName: "gcsName",
    columnName: "Image",
    type: "image",
  },
  {
    fieldName: "name",
    columnName: "Name",
  },
  {
    fieldName: "description",
    columnName: "Description",
  },
  {
    fieldName: "createdAt",
    columnName: "Created At",
    type: "date",
  },
];

const Library = () => {
  const library = useAppSelector((state) => state.library.library);
  const loading = useAppSelector((state) => state.library.loading);
  const total = useAppSelector((state) => state.library.metadata.total);
  const page = useAppSelector((state) => state.library.metadata.page);
  const limit = 20;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedSortOptions, setSelectedSortOptions] = React.useState<any[]>(
    []
  );
  const [sortValue, setSortValue] = React.useState<any>("");

  const onEditClick = (image: LibraryImage) => {
    navigate(ROUTES_PATHS.Library.imagePage.replace(":id", image._id));
  };
  const onAddNewClick = () => {
    navigate(ROUTES_PATHS.Library.imagePage.replace(":id", "add-new"));
  };

  const getImages = useCallback(
    (page: number, search?: string) => {
      const sortVal = librarySortOptions.find(
        (item) => item.id === selectedSortOptions[0]
      )?.value;

      let params: IGetLibraryParams = {
        sortBy: sortVal?.sortBy || "createdAt",
        sortDir: sortVal?.sortDir || "desc",
        skip: (page - 1) * limit,
        limit: limit,
        page: page,
      };

      if (search && search.trim().length > 0) {
        params["search"] = search;
      }
      dispatch(fetchLibraryStart({ params }));
    },
    [dispatch, selectedSortOptions]
  );

  return (
    <Table<LibraryImage>
      title={"Library"}
      list={library}
      columns={columns}
      getItems={getImages}
      limit={limit}
      page={page}
      total={total}
      onEditClick={onEditClick}
      withAddNew
      onAddNewClick={onAddNewClick}
      loading={loading}
      selectedSortOptions={selectedSortOptions}
      setSelectedSortOptions={setSelectedSortOptions}
      sortValue={sortValue}
      setSortValue={setSortValue}
      withSearch={true}
    />
  );
};

export default Library;
