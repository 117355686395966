import styled from "styled-components";

import logo from "../../assets/images/CalmiGo.png";
import { Image } from "@fluentui/react-components";

const LoadingContainer = styled.div`
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.25);
    }

    100% {
      transform: scale(1);
    }
  }
  animation: heartbeat 1s infinite;
  opacity: 1;
  transition: opacity 500ms;
`;

const LoadingLogo = (props: { darkLogo?: boolean }) => {
  return (
    <LoadingContainer style={{ zIndex: "1" }}>
      <Image alt="logo" src={logo} width={300} height={300} />
    </LoadingContainer>
  );
};

export default LoadingLogo;
