import styled from "styled-components";

import { IInputUI } from "../../../interfaces/components/ui/inputs";

export const StyledInput = styled.input<IInputUI>((props: IInputUI) => {
  const varient = props.varient || "contained";
  const fontVarient = props.fontVarient || "body1";
  const inputTheme = props.theme.inputs.varient[varient];
  const fontTheme = props.theme.typography.varient[fontVarient];
  const fontColor = props.color
    ? props.theme.palette.typography[props.color]
    : fontTheme.color;
  const placeholderColor = props.placeholderColor
    ? props.theme.palette.typography[props.placeholderColor]
    : inputTheme.placeholder;

  return `
    height: 63px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border: none;
    font-weight: 400;
    font-size: ${fontTheme.fontSize};
    margin: 0;
    padding:${varient === "contained" ? "0 28px" : "none"};
    border-radius:${varient === "contained" ? "4px" : null};
    background-color: ${inputTheme.backgroundColor};
    border-bottom: 1px solid;
    border-bottom-color: ${inputTheme.borderBottomColor};
    color: ${fontColor};
    font-family: ${fontTheme.fontFamily};
    
    &:disabled{
        opacity: 0.5;
    }
    &.invalid{
        border-bottom: 1px solid ${props.theme.palette.error.light};
    }
    &:focus {
        outline: none;
    }
    ::placeholder {
        color: ${placeholderColor};
    }
    `;
});
