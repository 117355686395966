import { Routes as Switch, Route, Navigate } from "react-router-dom";
import Logout from "../components/logout/Logout";
import MainAppLayout from "../components/hoc/MainAppLayout";
import { ROUTES } from "./constants";
import usePermission from "../hooks/usePermission";
import { ROUTES_PATHS } from "../common";
import { useSelector } from "react-redux";
import { RootState } from "services/rootReducer";

export const NavigationSwitch = () => {
  const adminAndStronger = usePermission("admin:>=");
  const auth = useSelector((state: RootState) => state.auth);

  const permission: string[] = auth.user.permission;

  const isAllow = (isAdmin: boolean, text: string) =>
    adminAndStronger || (!isAdmin && permission.includes(text));
  return (
    <Switch>
      {ROUTES.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <MainAppLayout
                allowToAccess={isAllow(route.adminOnly, route.permissionKey)}
              >
                {<route.mainComponent metadata={route.metadata || null} />}
              </MainAppLayout>
            }
          />
        );
      })}

      <Route path={ROUTES_PATHS.Auth.logout} element={<Logout />} />
      <Route
        path="/"
        element={
          <Navigate
            to={
              adminAndStronger
                ? ROUTES_PATHS.Dashboard.library
                : ROUTES.find(({ adminOnly, permissionKey }) =>
                    isAllow(adminOnly, permissionKey)
                  )?.path || "404"
            }
            replace
          />
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={
              adminAndStronger
                ? ROUTES_PATHS.Dashboard.library
                : ROUTES_PATHS.Dashboard.accounts
            }
            replace
          />
        }
      />
    </Switch>
  );
};
