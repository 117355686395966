//Style
import { useIconsStyles } from "../../hooks/useStyles";
import { LargeTitle } from "@fluentui/react-components";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";

//Common
import { ROUTES_PATHS } from "common";
import { FlexColumn } from "../common/containers";
import { TitleContainer } from "../common/containers";

//Hooks
import { useAsync } from "../../hooks/useAsync";

//Interfaces
import { ISubscription } from "../../interfaces/models";

//Service
import { subscriptionApi } from "../../services/api";

//Components
import { SubscriptionPageForm } from "./components";
import { CreateSubscription } from "./components/createSubscription";

export const SubscriptionPage = () => {
  const { id } = useParams();
  const location = useLocation();

  const search = location.state?.search;

  const iconsClasses = useIconsStyles();
  const navigate = useNavigate();

  const isAdd = id === "add-new";

  const { value: subscription, refetch } = useAsync<ISubscription>(
    () => subscriptionApi.getSubscriptionById({ id: id || "" }),
    [id],
    !isAdd
  );

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      <TitleContainer>
        <IosArrowLtr24Filled
          onClick={() => {
            navigate(ROUTES_PATHS.Dashboard.subscription, {
              state: { search },
            });
          }}
          className={iconsClasses.loginArrow}
        />
        <LargeTitle>{isAdd && "Create "}Subscription</LargeTitle>
      </TitleContainer>
      {isAdd ? (
        <CreateSubscription />
      ) : (
        subscription && (
          <SubscriptionPageForm refetch={refetch} subscription={subscription} />
        )
      )}
    </FlexColumn>
  );
};
