import {
  Select,
  Drawer,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useState } from "react";

//StyledComponents
import { StyledButton } from "styledComponents";
import {
  Root,
  Filters,
  Buttons,
  selectSX,
  CloseButton,
} from "./FilterDrawer.style";

//Utils
import { max, min } from "lodash";
import { RANGE_OPTION, valueText, valueToRange } from "./utils";

export const FilterDrawer = ({
  hideComponents,
  open,
  onClose,
  initialValues,
  onSubmit,
}: {
  onSubmit: (ages?: [number, number], genders?: string[]) => void;
  open: boolean;
  onClose: () => void;
  initialValues: { ages?: number[]; genders?: string[] };
  hideComponents?: { age?: boolean; gender?: boolean };
}) => {
  const [ages, setAges] = useState<number[] | undefined>(initialValues?.ages);
  const [gender, setGender] = useState<string[] | undefined>(
    initialValues?.genders
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{ ".MuiPaper-root": { width: "25rem", overflow: "visible" } }}
      onClose={onClose}
    >
      <Root>
        <CloseButton onClick={onClose} size="medium" aria-label="add">
          <ArrowForwardIos />
        </CloseButton>
        <Filters>
          {!hideComponents?.age && (
            <FormControl>
              <InputLabel>Age Category</InputLabel>
              <Select
                sx={selectSX}
                labelId="age-multiple-checkbox-label"
                id="age-multiple-checkbox"
                multiple
                value={ages}
                label="Age Category"
                onChange={(event: SelectChangeEvent<typeof ages>) => {
                  const {
                    target: { value },
                  } = event;
                  value && setAges(value as number[]);
                }}
                renderValue={(selected) =>
                  selected?.map((value) => valueText(value))?.join(", ")
                }
              >
                {RANGE_OPTION.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    <Checkbox checked={ages?.includes(value)} />
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {!hideComponents?.gender && (
            <FormControl>
              <InputLabel>Gender</InputLabel>
              <Select
                sx={selectSX}
                labelId="multiple-checkbox-label"
                id="multiple-checkbox"
                multiple
                value={gender}
                label="gender"
                onChange={(event: SelectChangeEvent<typeof gender>) => {
                  const {
                    target: { value },
                  } = event;
                  setGender(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                renderValue={(selected) => selected?.join(", ")}
              >
                {["Male", "Female", "Non-Binary", "Other"].map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={gender?.includes(name)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Filters>
        <Buttons>
          <StyledButton
            sx={{ width: "10rem", fontSize: "1.5rem" }}
            addBorder
            onClick={() => {
              const values = ages
                ?.map((ageIndex) => valueToRange(ageIndex))
                .flat();
              onSubmit(
                values?.length
                  ? [min(values) || 0, max(values) || 0]
                  : undefined,
                gender
              );
              onClose();
            }}
          >
            Generate
          </StyledButton>
          <StyledButton
            sx={{ width: "6rem", fontSize: "1.5rem" }}
            onClick={() => {
              setAges([]);
              setGender([]);
            }}
          >
            Reset
          </StyledButton>
        </Buttons>
      </Root>
    </Drawer>
  );
};
