import { SxProps } from "@mui/system";
import { ReactNode } from "react";
import { Button } from "./StyledButton.style";

export const StyledButton = ({
  loading,
  icon,
  variant,
  children,
  sx,
  onClick,
  addBorder,
  ...props
}: {
  loading?: boolean;
  icon?: ReactNode;
  variant?: "text" | "outlined" | "contained";
  children: ReactNode;
  sx?: SxProps;
  onClick: () => void;
  addBorder?: boolean;
  [x: string]: any;
}) => {
  return (
    <Button
      {...props}
      onClick={onClick}
      addBorder={addBorder}
      sx={sx}
      loading={loading}
      loadingPosition={icon ? "start" : undefined}
      startIcon={icon}
      variant={variant}
    >
      {children}
    </Button>
  );
};
