import { FormComponentsProps } from "interfaces";
import FormInput from "../../../ui/inputs/FormInput";
import { FullAccount } from "interfaces/models";

export const Inventories = ({ value }: FormComponentsProps<FullAccount>) => {
  return (
    <FormInput
      value={value || "0"}
      style={{
        marginTop: "5px",
        pointerEvents: "none",
        width: "100%",
      }}
    />
  );
};
