import { useEffect, useState } from "react";

export const ShortIdToToCopy = ({ value }: { value: string; row: any }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 1500);
    }
  }, [open]);

  const shortValue = value.length > 4 ? value.slice(-4) : value;

  return (
    <span
      onClick={() => {
        if (value) {
          setOpen(true);
          navigator.clipboard.writeText(value);
        }
      }}
    >
      {open ? "Copied" : shortValue}
    </span>
  );
};
