import { styled } from "@mui/material";
import { fonts } from "theme/theme";

export const Title = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
});

export const Root = styled("div")({
  minHeight: "30rem",
  height: "30rem",
});

export const Container = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const Logs = styled("div")({
  maxWidth: "75rem",
});
export const Data = styled("span")<{ isColor?: boolean }>(({ isColor }) => ({
  fontSize: "1.3rem",
  fontFamily: fonts.fontFamily.ragular,
  color: isColor ? "red" : "black",
  whiteSpace: "nowrap",
}));

export const DataLast = styled("span")<{ isColor?: boolean }>(
  ({ isColor }) => ({
    fontSize: "1.3rem",
    fontFamily: fonts.fontFamily.ragular,
    color: isColor ? "red" : "black",

    wordWrap: "break-word",
  })
);
