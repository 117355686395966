import { FormGroup } from "@mui/material";
import { CheckBox } from "components";

export const CheckBoxGroup = ({
  options,
  selectedValue,
  onChange,
}: {
  options: { label: string; value: any }[];
  selectedValue: any;
  onChange: (value: any) => void;
}) => {
  return (
    <FormGroup>
      {options.map(({ label, value }) => (
        <CheckBox
          key={value}
          label={label}
          onChange={() => onChange(value)}
          checked={selectedValue === value}
        />
      ))}
    </FormGroup>
  );
};
