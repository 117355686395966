import { useEffect, useState } from "react";
import {
  Button,
  LargeTitle,
  Subtitle2,
  Label,
  tokens,
  Image,
} from "@fluentui/react-components";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";
import { useParams, useNavigate } from "react-router-dom";
import FormInput from "components/ui/inputs/FormInput";
import LoaderContainer from "components/ui/loaders/LoaderContainer";
import { useForm } from "../../../hooks/useForm";
import { useButtonStyles, useIconsStyles } from "../../../hooks/useStyles";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { SceneItem } from "../../../interfaces/models/scene";
import { ROUTES_PATHS } from "../../../common/constants";
import AlertPopup from "components/common/AlertPopup";
import {
  FormContainer,
  FormInputContainer,
  TitleContainer,
  FlexColumn,
  FlexRowSpaced,
  FlexRowWrapped,
} from "components/common/containers";
import ErrorMsg from "components/common/ErrorMsg";
import ChooseLibraryImg from "components/choose-library-img/ChooseLibraryImg";
import { getImageSource } from "../../../utils/files-utils";
import {
  cleanEditSceneItemState,
  createSceneItemStart,
  deleteSceneItemStart,
  getSceneItemStart,
  updateSceneItemStart,
} from "../../../services/features/edit-scene-item/edit-scene-item-slice";
import {
  ICreateSceneItemBody,
  IUpdateSceneItemBody,
} from "../../../interfaces/api/scenes";
import usePermission from "../../../hooks/usePermission";

export const SceneItemPage = (props: { metadata: any }) => {
  const { id } = useParams();
  const currItem = useAppSelector((state) => state.editSceneItem.item);
  const currScene = useAppSelector((state) => state.editScene.scene);
  const { operation, requestState, loading } = useAppSelector(
    (state) => state.editSceneItem
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const iconsClasses = useIconsStyles();
  const buttonClasses = useButtonStyles();

  useEffect(() => {
    if (id !== "add-new")
      dispatch(getSceneItemStart({ sceneItemId: id || "" }));
    return () => {
      dispatch(cleanEditSceneItemState());
    };
  }, []);

  useEffect(() => {
    if (
      requestState === "finish" &&
      ["delete", "update", "create"].includes(operation || "")
    ) {
      navigate(
        ROUTES_PATHS.Scene.scenePage.replace(":id", currScene?._id || "")
      );
    }
  }, [operation, requestState]);

  const onDeleteClick = () => {
    setShowDeleteModal(false);
    dispatch(
      deleteSceneItemStart({
        sceneItemId: id || "",
        sceneId: currItem?.sceneId || "",
      })
    );
  };

  const openDeletePopup = () => {
    setShowDeleteModal(true);
  };

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      {loading ? <LoaderContainer /> : null}
      <AlertPopup
        title="Are you sure you want to delete?"
        open={showDeleteModal}
        onOpenChange={setShowDeleteModal}
        onActionClick={onDeleteClick}
      />
      <FlexRowSpaced>
        <TitleContainer>
          <IosArrowLtr24Filled
            onClick={() => {
              navigate(
                ROUTES_PATHS.Scene.scenePage.replace(
                  ":id",
                  currScene?._id || ""
                )
              );
            }}
            className={iconsClasses.loginArrow}
          />
          <LargeTitle>Scene Item</LargeTitle>
        </TitleContainer>
        {id !== "add-new" && !currScene?.isPublished ? (
          <Button
            appearance="primary"
            style={{ height: "40px" }}
            className={buttonClasses.delete}
            onClick={openDeletePopup}
          >
            Delete
          </Button>
        ) : null}
      </FlexRowSpaced>
      {id === "add-new" ? (
        <SceneItemPageForm
          isEdit={false}
          item={null}
          metadata={props.metadata.createForm}
        />
      ) : currItem ? (
        <SceneItemPageForm
          isEdit={true}
          item={currItem}
          metadata={props.metadata.editForm}
        />
      ) : null}
    </FlexColumn>
  );
};

const getInitialFormValues = (currScene: any) => {
  return { ...currScene };
};

const SceneItemPageForm = (props: {
  item: any;
  metadata: any;
  isEdit: boolean;
}) => {
  const { item, metadata, isEdit } = props;

  const loading = useAppSelector((state) => state.editSceneItem.loading);
  const currScene = useAppSelector((state) => state.editScene.scene);
  const dispatch = useAppDispatch();
  const buttonClasses = useButtonStyles();
  const writeAndStronger = usePermission("write:>=");

  const getValidationsObj = () => {
    let validationsObj = {};

    if (metadata) {
      Object.keys(metadata).map((key, index) => {
        let validationRules = {};

        if (metadata[key].required) {
          validationRules = {
            ...validationRules,
            required: {
              value: true,
              message: "required",
            },
          };
        }
        validationsObj = {
          ...validationsObj,
          [key]: {
            ...validationRules,
          },
        };
      });
    }
    return validationsObj;
  };

  const onEditSubmit = (data: SceneItem) => {
    if (isEdit) {
      const params: IUpdateSceneItemBody = {
        id: data._id,
        data: {
          sceneId: data.sceneId,
          name: data.name,
          price: data.price,
          serialNumber: data.serialNumber,
          imagePath: data.imagePath,
          thumbnailPath: data.thumbnailPath,
          dependsOn: data.dependsOn,
          deliveryTime: data.deliveryTime,
        },
      };

      dispatch(updateSceneItemStart(params));
    } else if (currScene) {
      dispatch(
        createSceneItemStart({
          sceneId: currScene._id,
          sceneItem: {
            sceneId: currScene._id,
            ...editSceneItem,
          },
        } as ICreateSceneItemBody)
      );
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleCustomChange,
    data: editSceneItem,
    errors,
  } = useForm<typeof item>({
    initialValues: getInitialFormValues(item),
    validations: getValidationsObj(),
    onSubmit: () => {
      if (onEditSubmit) {
        onEditSubmit(editSceneItem);
      }
    },
  });

  const onEditClick = () => {
    handleSubmit();
  };

  return (
    <FlexColumn>
      <FormContainer style={{ opacity: loading ? "0.3" : "1" }}>
        {metadata != null ? (
          <>
            <FlexRowWrapped style={{ gap: "40px" }}>
              {Object.keys(metadata).map((key, index) => {
                return metadata[key] && metadata[key].type !== "image" ? (
                  <FormInputContainer key={index}>
                    <Label>
                      {metadata[key].fieldName}:
                      {metadata[key].required ? (
                        <>
                          &nbsp;{" "}
                          <Subtitle2
                            style={{
                              color: tokens.colorPaletteCranberryBorderActive,
                            }}
                          >
                            *
                          </Subtitle2>{" "}
                        </>
                      ) : null}
                    </Label>
                    <FormInput
                      value={editSceneItem[key] || ""}
                      style={{
                        marginTop: "5px",
                        pointerEvents:
                          metadata[key].editable || !item ? "unset" : "none",
                      }}
                      placeholder={metadata[key].fieldName}
                      onChange={handleChange(key)}
                      errorMessage={errors[key]}
                      type={metadata[key].type || "string"}
                    />
                  </FormInputContainer>
                ) : null;
              })}
            </FlexRowWrapped>
            <FlexRowWrapped>
              {Object.keys(metadata).map((key, index) => {
                return metadata[key] && metadata[key].type === "image" ? (
                  <FormInputContainer style={{ width: "unset" }} key={index}>
                    <Label>
                      {metadata[key].fieldName}:
                      {metadata[key].required ? (
                        <>
                          &nbsp;{" "}
                          <Subtitle2
                            style={{
                              color: tokens.colorPaletteCranberryBorderActive,
                            }}
                          >
                            *
                          </Subtitle2>{" "}
                        </>
                      ) : null}
                    </Label>
                    {!metadata[key].editable || editSceneItem[key] ? (
                      <>
                        <Image
                          width={150}
                          src={getImageSource(editSceneItem[key]) || undefined}
                        />
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                          btnTitle={"Change Image"}
                        />
                      </>
                    ) : (
                      <>
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                        />
                        {errors[key] ? (
                          <ErrorMsg
                            message={errors[key] || ""}
                            withIcon={true}
                          />
                        ) : null}
                      </>
                    )}
                  </FormInputContainer>
                ) : null;
              })}
            </FlexRowWrapped>
          </>
        ) : null}
      </FormContainer>
      {writeAndStronger ? (
        <Button
          className={buttonClasses.primary}
          appearance="primary"
          style={{ width: "250px", margin: "20px 0" }}
          onClick={onEditClick}
        >
          Save
        </Button>
      ) : null}
    </FlexColumn>
  );
};
