import axiosInstance from "../../config/axios";
import ServerRoutes from "./routes";
import { getParamsUrl, handleAxiosError } from "./utils/common";
import { ISubscription } from "../../interfaces/models";

const getSubscription = async (params: {
  limit: number;
  search?: string;
  page?: number;
  skip?: number;
}): Promise<ISubscription[]> => {
  try {
    const url = getParamsUrl(ServerRoutes.subscriptions.subscriptions, params);
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error: any) {
    const err = handleAxiosError(error);
    console.error(err.data);
    return [];
  }
};

const getSubscriptionCount = async (params: {
  search?: string;
}): Promise<number> => {
  try {
    const url = getParamsUrl(
      ServerRoutes.subscriptions.subscriptionsCount,
      params
    );
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error: any) {
    const err = handleAxiosError(error);
    console.error(err.data);
    return 1;
  }
};

const getSubscriptionById = async (params: {
  id: string;
}): Promise<ISubscription> => {
  try {
    let url = `${ServerRoutes.subscriptions.getSubscription}/?id=${params.id}`;
    const { data } = await axiosInstance.get(url);

    return data;
  } catch (error: any) {
    const err = handleAxiosError(error);
    console.error(err.data);
    return {} as ISubscription;
  }
};

const getSubscriptionByCode = async (params: {
  code: string;
}): Promise<ISubscription> => {
  try {
    let url = `${ServerRoutes.subscriptions.getByActivationCode}/?code=${params.code}`;
    const { data } = await axiosInstance.get(url);

    return data;
  } catch (error: any) {
    const err = handleAxiosError(error);
    console.error(err.data);
    return {} as ISubscription;
  }
};

export const subscriptionApi = {
  getSubscription,
  getSubscriptionById,
  getSubscriptionByCode,
  getSubscriptionCount,
};
