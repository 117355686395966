import { Field } from "interfaces";
import { FullAccount } from "../../../../../interfaces/models";
import { SubscriptionCodeNav } from "../../subscriptionCodeNav";
import { DateFormatForm } from "../../../../dateFormatForm";
import { Inventories } from "../../inventories";
import { UserLevel } from "../../userLevel";

const USER_DETAILS: Field<FullAccount>[] = [
  { key: "account._id", label: "User ID", withCopy: true },
  { key: "account.fullName", label: "Name", withCopy: true },
  { key: "account.email", label: "Email Address", withCopy: true },
  { key: "account.phone", label: "Phone Number", withCopy: true },
];
const ACCOUNT_DETAILS: Field<FullAccount>[] = [
  {
    key: "account.createdAt",
    label: "Registration date",
    Components: DateFormatForm,
  },
  {
    key: "account.activationCode",
    label: "Activation code",
    Components: SubscriptionCodeNav,
  },
  {
    key: "account.subEnd",
    label: "Subscription end date",
    Components: DateFormatForm,
  },
  { key: "player.lastAppOpen", label: "Last Login" },
];
const PLAYER_DETAILS: Field<FullAccount>[] = [
  {
    key: "playerProgress.completedScenes",
    label: "User Level",
    Components: UserLevel,
  },
  { key: "inventory.coin", label: "Coins", Components: Inventories },
  { key: "inventory.key", label: "Keys", Components: Inventories },
  { key: "inventory.keySlot", label: "Key Slots", Components: Inventories },
  {
    key: "inventory.fastDelivery",
    label: "Fast delivery",
    Components: Inventories,
  },
];
const LEVEL_DETAILS: Field<FullAccount>[] = [
  {
    key: "playerProgress.currentSceneProgress.numOfPlayerItems",
    label: "Current Scene Items",
  },
  {
    key: "playerProgress.currentSceneProgress.deviceUses",
    label: "Current Scene Uses",
  },
];

export const ACCOUNT_FORM = [
  USER_DETAILS,
  ACCOUNT_DETAILS,
  PLAYER_DETAILS,
  LEVEL_DETAILS,
];
