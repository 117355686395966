import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATHS } from "common/constants";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchScenesStart } from "../../services/features/scenes/scenes-slice";
import {
  IGetScenesParams,
  scenesSortOptions,
} from "../../interfaces/api/scenes";
import { Scene } from "../../interfaces/models/scene";
import { columnFields, Table } from "components";

const columns: columnFields[] = [
  {
    fieldName: "serialNumber",
    columnName: "Serial Number",
  },
  {
    fieldName: "name",
    columnName: "Name",
  },
  {
    fieldName: "imageBackgroundPath",
    columnName: "Background Image",
    type: "image",
  },
  {
    fieldName: "imageCompletedPath",
    columnName: "Completed Path Image",
    type: "image",
  },
  {
    fieldName: "imageThumbnailPath",
    columnName: "Thumbnail Path Image",
    type: "image",
  },
  {
    fieldName: "isPublished",
    columnName: "Published",
    type: "boolean",
  },
  {
    fieldName: "createdAt",
    columnName: "Created At",
    type: "date",
  },
];

export const Scenes = () => {
  const scenes = useAppSelector((state) => state.scenes.scenes);
  const loading = useAppSelector((state) => state.scenes.loading);
  const total = useAppSelector((state) => state.scenes.metadata.total);
  const page = useAppSelector((state) => state.scenes.metadata.page);
  const limit = 20;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedSortOptions, setSelectedSortOptions] = React.useState<any[]>(
    []
  );
  const [sortValue, setSortValue] = React.useState<any>("");

  const onEditClick = (scene: Scene) => {
    navigate(ROUTES_PATHS.Scene.scenePage.replace(":id", scene._id));
  };
  const onAddNewClick = () => {
    navigate(ROUTES_PATHS.Scene.scenePage.replace(":id", "add-new"));
  };

  const getScenes = useCallback(
    (page: number, search?: string) => {
      const params: IGetScenesParams = {
        skip: (page - 1) * limit,
        limit: limit,
        page: page,
      };

      if (search && search.trim().length > 0) {
        params["search"] = search;
      }
      dispatch(fetchScenesStart({ params }));
    },
    [dispatch, selectedSortOptions]
  );

  return (
    <Table<Scene>
      title={"Scenes"}
      list={scenes}
      columns={columns}
      getItems={getScenes}
      limit={limit}
      page={page}
      total={total}
      onEditClick={onEditClick}
      withAddNew
      onAddNewClick={onAddNewClick}
      loading={loading}
      sortOptions={scenesSortOptions}
      selectedSortOptions={selectedSortOptions}
      setSelectedSortOptions={setSelectedSortOptions}
      sortValue={sortValue}
      setSortValue={setSortValue}
      withSearch={true}
    />
  );
};
