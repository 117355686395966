import { Button, styled } from "@mui/material";
import { fonts } from "theme/theme";

export const PopupButton = styled(Button)(() => ({
  height: "fit-content",
  width: "fit-content",
  color: "#0897a7",
  fontFamily: fonts.fontFamily.bold,
  fontSize: "1.2rem",
}));

export const ActionButton = styled(Button)(() => ({
  height: "fit-content",
  width: "fit-content",
  color: "#0897a7",
  fontFamily: fonts.fontFamily.bold,
  fontSize: "1.2rem",
  border: "0.2rem solid #045A6B",
  borderRadius: "1rem",
}));
