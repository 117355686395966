import { ChangeEvent, useState } from "react";
import _ from "lodash";
import { IFileToUpload } from "../components/common/upload-file/Dropzone";

interface Validation {
  required?: {
    value: boolean;
    message: string;
  };
  pattern?: {
    value: string;
    message: string;
  };
  custom?: {
    isValid: (value: string) => boolean;
    message: string;
  };
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

type Validations<T extends {}> = Partial<Record<keyof T, Validation>>;

export const useForm = <T extends Record<keyof T, any> = {}>(options?: {
  validations?: Validations<T>;
  initialValues?: Partial<T>;
  onSubmit?: () => void;
}) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});

  const handleChange =
    <S extends unknown>(key: keyof T, sanitizeFn?: (value: string) => S) =>
    (ev: ChangeEvent<HTMLInputElement>) => {
      const value = sanitizeFn ? sanitizeFn(ev.target.value) : ev.target.value;
      const update = {
        ...data,
        [key]: value,
      };
      setData(update);
      if (!_.isEmpty(errors)) {
        validate(update);
      }
    };

  const handleCustomChange = (
    key: keyof T,
    val: IFileToUpload | string | null
  ) => {
    const update = {
      ...data,
      [key]: val,
    };
    setData((prevState) => ({
      ...prevState,
      ...update,
    }));

    if (!_.isEmpty(errors)) {
      validate(update);
    }
  };

  //for game config
  const handleInnerChange = (key: keyof T, innerKey: string, val: any) => {
    const value = val;
    const update = {
      ...data,
      [key]: { ...data[key], [innerKey]: value },
    };
    setData(update);
    if (!_.isEmpty(errors)) {
      validate(update);
    }
  };

  //for game config
  const handleInnerNotificationChange = (
    key: keyof T,
    innerKey: string,
    notificationKey: string,
    val: any
  ) => {
    const value = val;
    const update = {
      ...data,
      [key]: {
        ...data[key],
        [innerKey]: { ...data[key][innerKey], [notificationKey]: value },
      },
    };
    setData(update);
    if (!_.isEmpty(errors)) {
      validate(update);
    }
  };

  const validate = (values: typeof data) => {
    let valid = true;
    const validations = options?.validations;
    if (validations) {
      const newErrors: ErrorRecord<T> = {};
      for (const key in validations) {
        const value = values[key];
        const validation = validations[key];
        if (validation?.required?.value && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }
      }

      if (!valid) {
        setErrors(newErrors);
        return valid;
      }
      setErrors({});
    }
    return valid;
  };

  const handleSubmit = async () => {
    const valid = validate(data);
    if (valid && options?.onSubmit) {
      options.onSubmit();
    }
  };

  return {
    data,
    handleChange,
    handleSubmit,
    handleCustomChange,
    handleInnerChange,
    handleInnerNotificationChange,
    errors,
    setData,
  };
};
