import { ROUTES_PATHS } from "../../../../common";
import { FullAccount, ISubscription } from "../../../../interfaces/models";

import { useAsync } from "../../../../hooks/useAsync";
import { subscriptionApi } from "../../../../services/api";
import { NavigationField } from "components/navigationField";
import { FormComponentsProps } from "interfaces";

export const SubscriptionCodeNav = ({
  value,
}: FormComponentsProps<FullAccount>) => {
  const { value: subscription } = useAsync<ISubscription>(
    () => subscriptionApi.getSubscriptionByCode({ code: value }),
    [value]
  );

  return (
    <NavigationField
      value={value}
      linkValidator={!!subscription}
      link={ROUTES_PATHS.Subscription.subscriptionPage.replace(
        ":id",
        subscription?._id!
      )}
    />
  );
};
