import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import { authCheckStart } from "./services/features/auth-user/auth-user-slice";
import { FullPage } from "./components/common/containers";
import { LoginNavigation } from "./pages";
import { NavigationSwitch } from "./routes/NavigationSwitch";
import PageLoader from "./components/ui/loaders/PageLoader";
import "./config";

function App() {
  const dispatch = useAppDispatch();
  const { authenticated, onStartUp, user } = useAppSelector(
    (state) => state.auth
  );

  useEffect(() => {
    dispatch(authCheckStart());
  }, [dispatch]);

  let view = (
    <FullPage>
      <PageLoader />
    </FullPage>
  );

  if (authenticated && !onStartUp && user) {
    view = (
      <Router>
        <NavigationSwitch />
      </Router>
    );
  }
  if (!authenticated && !onStartUp) {
    view = <LoginNavigation />;
  }

  return <>{view}</>;
}

export default App;
