import { styled } from "@mui/material";
import { fonts } from "theme/theme";

export const Container = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});
export const Data = styled("span")({
  fontSize: "1.3rem",
  fontFamily: fonts.fontFamily.ragular,
});
