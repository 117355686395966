import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { authLogoutStart } from "../../services/features/auth-user/auth-user-slice";

const Logout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(authLogoutStart());
  }, []);
  return null;
};

export default React.memo(Logout);
