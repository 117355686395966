import React, { useCallback, useEffect } from "react";
import { FlexColumn, FlexRowSpaced } from "../common/containers";
import {
  Button,
  LargeTitle,
  Subtitle2,
  Label,
  tokens,
  Image,
} from "@fluentui/react-components";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";
import { useButtonStyles, useIconsStyles } from "../../hooks/useStyles";
import { useParams, useNavigate } from "react-router-dom";
import FormInput, { FormTextArea } from "../ui/inputs/FormInput";
import { useForm } from "../../hooks/useForm";
import Dropzone from "../common/upload-file/Dropzone";
import {
  FormContainer,
  FormInputContainer,
  TitleContainer,
} from "../common/containers";
import {
  cleanEditLibraryImageState,
  createLibraryImageFailed,
  createLibraryImageStart,
  createLibraryImageSuccess,
  deleteLibraryImageStart,
  getLibraryImageStart,
  updateLibraryImageStart,
} from "../../services/features/edit-library-image/edit-library-image-slice";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  IFileUpdate,
  IGetPersignedUrlParams,
} from "../../interfaces/api/files";
import filesApi from "../../services/api/files-api";
import { ROUTES_PATHS } from "../../common/constants";
import LoaderContainer from "../ui/loaders/LoaderContainer";
import AlertPopup from "../common/AlertPopup";
import {
  getInitialFormValues,
  getValidationsObj,
} from "../../utils/forms-utils";
import usePermission from "../../hooks/usePermission";

const LibraryImagePage = (props: { metadata: any }) => {
  const { id } = useParams();
  const iconsClasses = useIconsStyles();
  const buttonClasses = useButtonStyles();
  const navigate = useNavigate();
  const currImage = useAppSelector(
    (state) => state.editLibraryImage.libraryImage
  );
  const dispatch = useAppDispatch();
  const { operation, requestState } = useAppSelector(
    (state) => state.editLibraryImage
  );
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const writeAndStronger = usePermission("write:>=");

  useEffect(() => {
    if (id !== "add-new") dispatch(getLibraryImageStart({ fileId: id || "" }));
    return () => {
      dispatch(cleanEditLibraryImageState());
    };
  }, []);

  useEffect(() => {
    if (
      requestState === "finish" &&
      ["delete", "update", "create"].includes(operation || "")
    ) {
      console.log("requestState == finish - Op: ", operation);
      if (["create"].includes(operation || "")) {
        navigate(ROUTES_PATHS.Library.imagePage.replace(":id", "add-new"));
        return;
      }

      navigate(ROUTES_PATHS.Dashboard.library);
    }
  }, [operation, requestState]);

  const onDeleteClick = () => {
    setShowDeleteModal(false);
    dispatch(deleteLibraryImageStart({ fileId: id || "" }));
  };

  const openDeletePopup = () => {
    setShowDeleteModal(true);
  };

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      <AlertPopup
        title="Are you sure you want to delete?"
        open={showDeleteModal}
        onOpenChange={setShowDeleteModal}
        onActionClick={onDeleteClick}
      />
      <FlexRowSpaced>
        <TitleContainer>
          <IosArrowLtr24Filled
            onClick={() => {
              navigate(-1);
            }}
            className={iconsClasses.loginArrow}
          />
          <LargeTitle>Image</LargeTitle>
        </TitleContainer>
        {id !== "add-new" && writeAndStronger ? (
          <Button
            appearance="primary"
            style={{ height: "40px" }}
            className={buttonClasses.delete}
            onClick={openDeletePopup}
          >
            Delete
          </Button>
        ) : null}
      </FlexRowSpaced>
      {id === "add-new" ? (
        <LibraryImagePageForm
          isEdit={false}
          libraryImage={null}
          metadata={props.metadata.createForm}
        />
      ) : currImage ? (
        <LibraryImagePageForm
          isEdit={true}
          libraryImage={currImage}
          metadata={props.metadata.editForm}
        />
      ) : null}
    </FlexColumn>
  );
};

const LibraryImagePageForm = (props: {
  libraryImage: any;
  metadata: any;
  isEdit: boolean;
}) => {
  const { libraryImage, metadata, isEdit } = props;

  const loading = useAppSelector((state) => state.editLibraryImage.loading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const buttonClasses = useButtonStyles();
  const writeAndStronger = usePermission("write:>=");

  const onEditSubmit = (data: typeof libraryImage) => {
    if (isEdit) {
      const params: IFileUpdate = {
        id: data._id,
        data: {
          name: data.name,
          description: data.description,
        },
      };
      dispatch(updateLibraryImageStart(params));
    } else {
      createLibraryImage();
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleCustomChange,
    data: editLibraryImage,
    errors,
  } = useForm<typeof libraryImage>({
    initialValues: getInitialFormValues(libraryImage),
    validations: getValidationsObj(metadata),
    onSubmit: () => {
      if (onEditSubmit) {
        onEditSubmit(editLibraryImage);
      }
    },
  });

  const onEditClick = () => {
    handleSubmit();
  };

  const createLibraryImage = useCallback(async () => {
    dispatch(createLibraryImageStart(editLibraryImage));
    try {
      if (!editLibraryImage.image) {
        return;
      }
      console.log(
        editLibraryImage.image.fileName.slice(
          editLibraryImage.image.fileName.lastIndexOf(".") + 1
        )
      );

      const params: IGetPersignedUrlParams = {
        fileName: editLibraryImage.name,
        description: editLibraryImage.description || "",
        extension: editLibraryImage.image.fileName.slice(
          editLibraryImage.image.fileName.lastIndexOf(".") + 1
        ),
      };
      const presignedUrlRes = await filesApi.getPresignedUrl(params);

      if (presignedUrlRes.error) {
        throw presignedUrlRes.error;
      }

      const url = presignedUrlRes.data.data;

      console.log("createLibraryImage: Uploading image...");

      const uploadRes = await filesApi.uplaodFile({
        url,
        file: editLibraryImage.image.blobFile,
        name: editLibraryImage.name,
        description: editLibraryImage.description,
      });
      if (uploadRes.error) {
        throw uploadRes.error;
      } else {
        console.log("createLibraryImage: Sleep(2s)...");

        setTimeout(() => {
          dispatch(createLibraryImageSuccess(editLibraryImage));
          console.log("createLibraryImage: Sleep(2s)...");
          navigate(ROUTES_PATHS.Library.imagePage.replace(":id", "add-new"));
          // navigate(ROUTES.Dashboard.library)
        }, 2000);
      }
    } catch (error: any) {
      dispatch(createLibraryImageFailed({ message: "Upload failed" }));
    }
  }, [dispatch, editLibraryImage]);

  return (
    <FlexColumn>
      {loading ? <LoaderContainer /> : null}
      <FormContainer style={{ opacity: loading ? "0.3" : "1" }}>
        {metadata != null ? (
          <>
            {Object.keys(metadata).map((key, index) => {
              return metadata[key] ? (
                <FormInputContainer key={index}>
                  <Label>
                    {metadata[key].fieldName}:
                    {metadata[key].required ? (
                      <>
                        &nbsp;{" "}
                        <Subtitle2
                          style={{
                            color: tokens.colorPaletteCranberryBorderActive,
                          }}
                        >
                          *
                        </Subtitle2>{" "}
                      </>
                    ) : null}
                  </Label>
                  {metadata[key].type === "textarea" ? (
                    <FormTextArea
                      value={editLibraryImage[key] || ""}
                      style={{
                        marginTop: "5px",
                        pointerEvents: metadata[key].editable
                          ? "unset"
                          : "none",
                      }}
                      placeholder={metadata[key].fieldName}
                      onChange={handleChange(key)}
                      errorMessage={errors[key]}
                    />
                  ) : metadata[key].type === "image" ? (
                    metadata[key].editable ? (
                      <Dropzone
                        title="Upload Image"
                        wrapperWidth={350}
                        wrapperHeight={350}
                        fileTypes={["image"]}
                        onUploadFileComplete={(file) =>
                          handleCustomChange(key, file)
                        }
                        withCrop={false}
                        originalImage={editLibraryImage[key] || null}
                      />
                    ) : (
                      <Image src={editLibraryImage[key]} />
                    )
                  ) : (
                    <FormInput
                      value={editLibraryImage[key] || ""}
                      style={{
                        marginTop: "5px",
                        pointerEvents: metadata[key].editable
                          ? "unset"
                          : "none",
                      }}
                      placeholder={metadata[key].fieldName}
                      onChange={handleChange(key)}
                      errorMessage={errors[key]}
                    />
                  )}
                </FormInputContainer>
              ) : null;
            })}
          </>
        ) : null}
      </FormContainer>

      {writeAndStronger ? (
        <Button
          className={buttonClasses.primary}
          appearance="primary"
          style={{ width: "250px" }}
          onClick={onEditClick}
        >
          Save
        </Button>
      ) : null}
    </FlexColumn>
  );
};

export default LibraryImagePage;
