//Interfaces
import { RolePicker } from "components";
import { Field } from "interfaces";
import { CmsAccountDto } from "interfaces/models";

const USER_DETAILS: Field<CmsAccountDto>[] = [
  { key: "email", label: "Email", required: true },
  { key: "fullName", label: "Full Name", required: true },
  { key: "phone", label: "Phone", required: true },
];

const PERSONAL_DETAILS: Field<CmsAccountDto> = {
  key: "role",
  label: "Role",
  Components: RolePicker,
  required: true,
};
const PASSWORD: Field<CmsAccountDto> = {
  key: "password",
  label: "Password",
  required: true,
};

export const CMS_ACCOUNT_FORM_ADD = [
  USER_DETAILS,
  [PASSWORD, PERSONAL_DETAILS],
];
export const CMS_ACCOUNT_FORM_UPDATE = [USER_DETAILS, [PERSONAL_DETAILS]];
