import { IUnique } from "../base";
import { Option } from "../components/common/Select";
import { IBoxesTypeOption } from "./box";

export interface DailyReward extends IUnique {
  day: number;
  prizeName: string;
  prize: DailyRewardPrizeType;
  amount: number;
  giftBoxType?: IBoxesTypeOption;
  updatedAt?: Date;
  createdAt?: Date;
}

export type DailyRewardPrizeType =
  | "coin"
  | "keySlot"
  | "key"
  | "fastDelivery"
  | "giftBox";

export const dailyRewardTypeOptions: Option<DailyRewardPrizeType>[] = [
  { id: "1", label: "Coin", value: "coin" },
  { id: "2", label: "Key Slot", value: "keySlot" },
  { id: "3", label: "Key", value: "key" },
  { id: "4", label: "Fast Delivery", value: "fastDelivery" },
  { id: "5", label: "Gift Box", value: "giftBox" },
];

export const giftBoxTypeOptions: Option<IBoxesTypeOption>[] = [
  { id: "1", label: "Common", value: "common" },
  { id: "2", label: "Rare", value: "rare" },
  { id: "3", label: "Epic", value: "epic" },
];
