import { useSelector } from "react-redux";
import { definedRoles } from "../security/Roles";
import { RootState } from "../services/rootReducer";

const useUserRole = () => {
  const auth = useSelector((state: RootState) => state.auth);
  return auth.role ? definedRoles[auth.role] : definedRoles["write"];
};

export default useUserRole;
