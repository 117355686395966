//Interfaces
import { Field } from "interfaces";
import { ISubscription } from "../../../../../interfaces/models";

//Components
import { AppUserNav } from "../../appUserNav";
import { ProductName } from "../../productName";
import { SubscriptionDate } from "../../subscriptionDate";
import { DateFormatForm } from "components/dateFormatForm";

const USER_DETAILS: Field<ISubscription>[] = [
  { key: "subscription_email", label: "Email", withCopy: true },
  { key: "subscription_phone_number", label: "Phone", withCopy: true },
  { key: "activation_code", label: "Activation Code", withCopy: true },
  {
    key: "end_subscription_date",
    label: "Subscription end-date",
    Components: SubscriptionDate,
  },
];
const APP_DETAILS: Field<ISubscription>[] = [
  { key: "app_user_email", label: "App User Email", withCopy: true },
  { key: "app_user_phone_number", label: "App User Phone", withCopy: true },
  { key: "app_user_id", label: "App User ID", Components: AppUserNav },
];
const PRODUCT_DETAILS: Field<ISubscription>[] = [
  { key: "subscription_id", label: "Subscription ID", withCopy: true },
  { key: "product_id", label: "Product ID", Components: ProductName },
];
const DEVICE_DETAILS: Field<ISubscription>[] = [
  { key: "lock_date", label: "Device Lock Date", Components: DateFormatForm },
];

export const SUBSCRIPTION_FORM = [
  USER_DETAILS,
  APP_DETAILS,
  PRODUCT_DETAILS,
  DEVICE_DETAILS,
];
