import { useCallback, useState } from "react";
import { useAsync } from "./useAsync";
import {
  Sort,
  IWithPagination,
  IFetchListResponse,
} from "interfaces/api/common";

export const useTableData = <T>(
  limit: number,
  callback: (
    params: IWithPagination & { search?: string }
  ) => Promise<IFetchListResponse<T>>,
  sortOption?: Sort[]
) => {
  const [metadata, setMetadata] = useState<{ page: number; total: number }>({
    page: 1,
    total: 0,
  });
  const [sortValue, setSortValue] = useState<any>("");
  const [selectedSortOptions, setSelectedSortOptions] = useState<any[]>([]);

  const getData = useCallback(
    (page: number, search?: string) => {
      const { sortBy, sortDir } = sortOption?.find(
        (item) => item.id === selectedSortOptions[0]
      )?.value || { sortBy: "createdAt", sortDir: "asc" }; //In case of undefine

      const params = {
        sortBy,
        sortDir,
        page,
        limit,
        ...(search && search.trim().length > 0 && { search }),
      };

      return callback(params);
    },
    [selectedSortOptions]
  );

  const { value: data, status } = useAsync(() => getData(1), []);

  const [list, setList] = useState(data?.data || []);

  const getItems = useCallback(
    (page: number, search?: string) => {
      getData(page, search).then(({ data, metadata }) => {
        setList(data);
        setMetadata(metadata);
      });
    },
    [selectedSortOptions]
  );
  return {
    list,
    status,
    getItems,
    selectedSortOptions,
    setSelectedSortOptions,
    sortValue,
    setSortValue,
    sortOption,
    ...metadata,
  };
};
