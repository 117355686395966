import { PayloadAction } from "@reduxjs/toolkit";
import { call, fork, put, takeLatest, delay } from "redux-saga/effects";
import jwt_decode from "jwt-decode";

import { DecodedData } from "../../../utils/utils";
import { removeToken, updateToken } from "../../../config/axios";
import { IBaseResponse } from "../../../interfaces/base";
import {
  authCheckStart,
  authCheckFailed,
  authCheckSuccess,
  loginStart,
  loginFailed,
  loginSuccess,
  verifyCodeStart,
  verifyCodeFailed,
  verifyCodeSuccess,
  authLogoutSuccess,
  failed,
  success,
  authLogoutStart,
  resendCodeStart,
  forgotPasswordStart,
  resetPasswordStart,
  forgotPasswordFailed,
  forgotPasswordSuccess,
  resetPasswordFailed,
  resetPasswordSuccess,
  setRole,
} from "./auth-user-slice";
import authApi from "../../api/auth-user-api";
import { getTokenRefreshTime } from "../../../utils/utils";
import {
  IForgotPassword,
  IResetPassword,
} from "../../../interfaces/components/forms/login";

function* authCheckState(): any {
  try {
    const token = localStorage.getItem("access_token");
    const id_token = localStorage.getItem("id_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (!token) {
      localStorage.clear();
      return yield put(authLogoutSuccess());
    }
    updateToken();
    let decoded: DecodedData = jwt_decode(token);
    const expiration = decoded.exp;
    const refreshTime = getTokenRefreshTime(token);

    if (
      (!expiration || expiration < new Date().getTime() / 1000) &&
      refresh_token
    ) {
      const { status, data } = yield call(
        authApi.refreshTokenLogin,
        refresh_token
      );
      if (status === 200 && data.access_token) {
        const { access_token, id_token, refresh_token, expires_in } = data;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("id_token", id_token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("expires_in", JSON.stringify(expires_in));

        decoded = jwt_decode(access_token);
        yield put(setRole({ role: decoded.role }));

        updateToken();
        yield fork(scheduleTokenRefresh, refreshTime);
        const user = getDecodedInfo(id_token);
        if (user) {
          return yield put(authCheckSuccess({ user }));
        } else {
          localStorage.clear();
          return yield put(
            authCheckFailed({ message: "Invalid token provided" })
          );
        }
      } else {
        localStorage.clear();
        removeToken();
        return yield put(authLogoutSuccess());
      }
    } else {
      updateToken();
      yield put(setRole({ role: decoded.role }));
      yield fork(scheduleTokenRefresh, refreshTime);
      const user = getDecodedInfo(id_token);
      if (user) {
        return yield put(authCheckSuccess({ user }));
      } else {
        localStorage.clear();
        return yield put(
          authCheckFailed({ message: "Invalid token provided" })
        );
      }
    }
  } catch (error: any) {
    return yield put(authCheckFailed(error));
  }
}

interface loginResponse extends IBaseResponse {
  data: {
    access_token: string;
    [key: string]: any;
  };
}
function* sendLoginRequest(
  action: PayloadAction<{ email: any; password: any }>
): any {
  try {
    const { email, password } = action.payload;
    const { data, status, error }: loginResponse = yield call(authApi.login, {
      email,
      password,
    });

    if (error) {
      yield put(loginFailed(error));
      return;
    }
    if (status === 200 && data.access_token && !data.two_factor) {
      const { access_token, id_token, refresh_token, expires_in } = data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("id_token", id_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("expires_in", JSON.stringify(expires_in));
      updateToken();
      const decoded: DecodedData = jwt_decode(access_token);
      yield put(setRole({ role: decoded.role }));
      const time = getTokenRefreshTime(data.access_token);
      yield fork(scheduleTokenRefresh, time);
      const user = getDecodedInfo(id_token);
      if (user) {
        yield put(loginSuccess({ ...data, user }));
      } else {
        localStorage.clear();
        yield put(loginFailed({ message: "Invalid token provided" }));
      }
    } else {
      // localStorage.clear();
      yield put(loginSuccess({ ...data, user: null }));
    }
  } catch (error: any) {
    yield put(loginFailed(error));
  }
}

function* resend2faCode(action: PayloadAction<{ token: string }>): any {
  try {
    const { error }: loginResponse = yield call(
      authApi.resendCode,
      action.payload.token
    );
    if (error) {
      yield put(failed(error));
      return;
    }
    yield put(success());
  } catch (error: any) {
    yield put(failed(error));
  }
}

function* send2faRequest(
  action: PayloadAction<{ code: string; token: string }>
) {
  try {
    const { code, token } = action.payload;
    const { data, status, error }: IBaseResponse = yield call(
      authApi.verify2faCode,
      code,
      token
    );

    if (error) {
      yield put(verifyCodeFailed(error));
      return;
    }
    if (status === 200 && data.data.access_token) {
      const { access_token, id_token, refresh_token, expires_in } = data.data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("id_token", id_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("expires_in", JSON.stringify(expires_in));
      updateToken();
      const decoded: DecodedData = jwt_decode(access_token);
      yield put(setRole({ role: decoded.role }));
      const time = getTokenRefreshTime(access_token);
      yield fork(scheduleTokenRefresh, time);
      const user = getDecodedInfo(id_token);
      if (user) {
        yield put(verifyCodeSuccess({ user: user }));
      } else {
        localStorage.clear();
        yield put(verifyCodeFailed({ message: "Invalid token provided" }));
      }
    } else {
      localStorage.clear();
    }
  } catch (error: any) {
    yield put(verifyCodeFailed(error));
  }
}

function* authUserLogout(): any {
  localStorage.clear();
  return yield put(authLogoutSuccess()); // need to change
  // try {
  //     let refreshToken = localStorage.getItem('refresh_token');
  //     if (refreshToken) {
  //         const { data, status, error }: IBaseResponse = yield call(authApi.logout, refreshToken);

  //         if (status === 200 && data.ok) {
  //             localStorage.clear();
  //             removeToken();
  //             return yield put(authLogoutSuccess());
  //         }
  //         else {
  //             return yield put(failed(error));
  //         }
  //     }
  //     localStorage.clear();
  //     removeToken();
  //     return yield put(authLogoutSuccess());;
  // } catch (error: any) {
  //     yield put(failed({ message: error.message }));
  // }
}

export const getDecodedInfo = (token: string | null) => {
  try {
    if (token) {
      const decoded = jwt_decode(token);
      return decoded;
    }
    return null;
  } catch (error) {
    return null;
  }
};

function* loadTokens() {
  try {
    let refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      return "refresh token not found";
    }
    const { status, data, error } = yield call(
      authApi.refreshTokenLogin,
      refreshToken
    );
    if (status === 200 && data.access_token) {
      const { access_token, id_token, refresh_token, expires_in } = data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("id_token", id_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("expires_in", JSON.stringify(expires_in));

      updateToken();
      const decoded: DecodedData = jwt_decode(access_token);
      yield put(setRole({ role: decoded.role }));
      const time = getTokenRefreshTime(data.access_token);
      yield fork(scheduleTokenRefresh, time);
    }
    return error;
  } catch (error) {
    return error;
  }
}

function* scheduleTokenRefresh(timer: number): any {
  try {
    yield delay(timer);
    const error = yield call(loadTokens);
    if (error) {
      yield put(authUserLogout());
    }
  } catch (error) {
    return;
  }
}

function* forgotPassword(action: PayloadAction<IForgotPassword>): any {
  try {
    const { data, error }: IBaseResponse = yield call(
      authApi.forgotPassword,
      action.payload
    );

    if (error) {
      yield put(forgotPasswordFailed(error));
      return;
    }
    yield put(forgotPasswordSuccess(data));
  } catch (error: any) {
    yield put(forgotPasswordFailed(error));
  }
}

function* resetPassword(action: PayloadAction<IResetPassword>): any {
  try {
    const { data, error }: IBaseResponse = yield call(authApi.resetPassword, {
      password: action.payload.password,
    });

    if (error) {
      yield put(resetPasswordFailed(error));
      return;
    }
    yield put(resetPasswordSuccess(data));
  } catch (error: any) {
    yield put(resetPasswordFailed(error));
  }
}

function* authUserSaga() {
  yield takeLatest(loginStart.type, sendLoginRequest);
  yield takeLatest(verifyCodeStart.type, send2faRequest);
  yield takeLatest(authCheckStart.type, authCheckState);
  yield takeLatest(authLogoutStart.type, authUserLogout);
  yield takeLatest(resendCodeStart.type, resend2faCode);
  yield takeLatest(forgotPasswordStart.type, forgotPassword);
  yield takeLatest(resetPasswordStart.type, resetPassword);
}

export default authUserSaga;
