import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { FormComponentsProps } from "interfaces";

export const RolePicker = ({ value, onChange }: FormComponentsProps) => {
  const ROLES = ["admin", "write", "viewonly"];
  return (
    <FormControl sx={{ width: "20rem" }}>
      <RadioGroup
        row
        value={value}
        onChange={(_, value) => {
          onChange && onChange("role", value);
        }}
        aria-labelledby="row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {ROLES.map((role) => (
          <FormControlLabel
            checked={role === value}
            value={role}
            control={<Radio />}
            label={role}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
