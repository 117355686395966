import React, { useCallback, useEffect } from "react";
import Table, { columnFields } from "../table/Table";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATHS } from "../../common/constants";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Box, boxesTypeOptions } from "../../interfaces/models/box";
import { IGetBoxesParams, boxesSortOptions } from "../../interfaces/api/boxes";
import { fetchBoxesStart } from "../../services/features/boxes/boxes-slice";
import { cleanEditBoxState } from "../../services/features/edit-box/edit-box-slice";

const columns: columnFields[] = [
  {
    fieldName: "name",
    columnName: "Name",
  },
  {
    fieldName: "price",
    columnName: "Price",
  },
  {
    fieldName: "boxType",
    columnName: "Type",
    type: "dropdownVal",
  },
  {
    fieldName: "isPublished",
    columnName: "Published",
    type: "boolean",
  },
  {
    fieldName: "createdAt",
    columnName: "Created At",
    type: "date",
  },
];

const Boxes = () => {
  const boxes = useAppSelector((state) => state.boxes.boxes);
  const loading = useAppSelector((state) => state.boxes.loading);
  const total = useAppSelector((state) => state.boxes.metadata.total);
  const page = useAppSelector((state) => state.boxes.metadata.page);
  const limit = 20;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedSortOptions, setSelectedSortOptions] = React.useState<any[]>(
    []
  );
  const [sortValue, setSortValue] = React.useState<any>("");

  const onEditClick = (box: Box) => {
    navigate(ROUTES_PATHS.Box.boxPage.replace(":id", box._id));
  };
  const onAddNewClick = () => {
    navigate(ROUTES_PATHS.Box.boxPage.replace(":id", "add-new"));
  };

  useEffect(() => {
    dispatch(cleanEditBoxState());
  }, []);

  const getboxes = useCallback(
    (page: number, search?: string) => {
      const sortVal = boxesSortOptions.find(
        (item) => item.id === selectedSortOptions[0]
      )?.value;

      let params: IGetBoxesParams = {
        sortBy: sortVal?.sortBy || "createdAt",
        sortDir: sortVal?.sortDir || "desc",
        skip: (page - 1) * limit,
        limit: limit,
        page: page,
      };

      if (search && search.trim().length > 0) {
        params["search"] = search;
      }
      dispatch(fetchBoxesStart({ params }));
    },
    [selectedSortOptions]
  );

  return (
    <Table<Box>
      title={"Boxes"}
      list={boxes}
      columns={columns}
      getItems={getboxes}
      limit={limit}
      page={page}
      total={total}
      onEditClick={onEditClick}
      withAddNew
      onAddNewClick={onAddNewClick}
      loading={loading}
      sortOptions={boxesSortOptions}
      selectedSortOptions={selectedSortOptions}
      setSelectedSortOptions={setSelectedSortOptions}
      sortValue={sortValue}
      setSortValue={setSortValue}
      withSearch={true}
      dropdownOptions={{ boxType: boxesTypeOptions }}
    />
  );
};

export default Boxes;
