import { Label, Subtitle2, tokens } from "@fluentui/react-components";
import { FormContainerRow } from "../common/containers";
import FormInput from "../ui/inputs/FormInput";
import { DetailsContainer } from "./Form.styled";
import { SxProps } from "@mui/system";
import { FieldData } from "interfaces/components";
import { ChangeEvent } from "react";

export const Form = ({
  details,
  row,
  containerSx,
  onChange,
}: {
  details: ({ key: string } & Omit<FieldData<typeof row>, "key">)[][];
  row: any;
  containerSx?: SxProps;
  onChange?: (key: string, value: string) => void;
}) => {
  return (
    <div>
      {details.map((pairs, index) => (
        <FormContainerRow key={index}>
          {pairs.map(
            ({ label, value, required, Components, withCopy, key }, index) => (
              <DetailsContainer sx={containerSx} key={index}>
                <Label>
                  {label && `${label}:`}
                  {required && (
                    <Subtitle2
                      style={{
                        color: tokens.colorPaletteCranberryBorderActive,
                      }}
                    >
                      {" *"}
                    </Subtitle2>
                  )}
                </Label>

                {Components ? (
                  <Components
                    onChange={onChange}
                    value={value}
                    row={row}
                    withCopy={withCopy}
                  />
                ) : (
                  <FormInput
                    withCopy={withCopy}
                    value={value || ""}
                    style={{
                      marginTop: "0.3rem",
                      width: "100%",
                    }}
                    onChange={
                      onChange
                        ? ({
                            target: { value },
                          }: ChangeEvent<HTMLInputElement>) =>
                            onChange(key, value)
                        : undefined
                    }
                    placeholder={label}
                  />
                )}
              </DetailsContainer>
            )
          )}
        </FormContainerRow>
      ))}
    </div>
  );
};
