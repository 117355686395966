import { Star } from "@mui/icons-material";

//Components
import { CreateButton } from "./Orders.style";
import { FormControlLabel, Switch } from "@mui/material";
import { CheckBox, columnFields, Table } from "components";

//Hooks
import { useState } from "react";
import { useToggle, useAsync } from "hooks/";

//Interfaces
import { Order as IOrder } from "interfaces/models";

//Services
import { shopifyApi } from "services/api/shopify-api";

export const Orders = () => {
  const [page, setPage] = useState(1);

  const selectedOrders = new Map<number, IOrder>([]);

  const { toggleValue, value: onlyUnThreated } = useToggle(true);
  const { value, status, refetch } = useAsync(
    () => shopifyApi.getOrders(onlyUnThreated, page),
    [onlyUnThreated, page]
  );
  const [isLoad, setIsLoad] = useState(false);

  const handleCreate = () => {
    setIsLoad(true);
    shopifyApi
      .createMultiOrders(Array.from(selectedOrders.values()))
      .then(() => {
        refetch();
        setIsLoad(false);
      });
  };
  const columns: columnFields<IOrder>[] = [
    {
      fieldName: "",
      columnName: (
        <CreateButton
          loading={status == "pending" || isLoad}
          onClick={handleCreate}
        >
          Create Orders
        </CreateButton>
      ),
      costumeRenderColumn({ row: { rowId, ...row } }) {
        return (
          <CheckBox
            disabled={row.treated}
            onChange={(_: any, checked: boolean) =>
              handleChecked(checked, rowId!, row)
            }
            checked={row.treated ? true : undefined}
          />
        );
      },
    },
    {
      columnName: "First Name",
      fieldName: "first_name",
    },
    {
      columnName: "Last Name",
      fieldName: "last_name",
    },
    {
      columnName: "Email",
      fieldName: "email",
    },
    {
      columnName: "City",
      fieldName: "city",
    },
    {
      columnName: "Phone",
      fieldName: "phone",
    },
    {
      columnName: "Language",
      fieldName: "language",
    },
    {
      columnName: "Color",
      fieldName: "color",
    },
    {
      columnName: "Handled",
      fieldName: "treated",
      costumeRenderColumn({ value }) {
        return value && <Star />;
      },
    },
  ];

  const handleChecked = (checked: boolean, rowId: number, data: IOrder) => {
    if (checked) {
      selectedOrders.set(rowId, data);
    } else {
      selectedOrders.delete(rowId);
    }
  };

  return (
    <div>
      <Table<IOrder>
        limit={10}
        list={value?.data || []}
        loading={status == "pending"}
        getItems={(page: number) => setPage(page)}
        total={value?.length || 0}
        page={page}
        title="B2B Orders"
        columns={columns}
        extraFiltersComponents={
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={onlyUnThreated}
                  value={onlyUnThreated}
                  onChange={() => {
                    toggleValue();
                    setPage(1);
                  }}
                />
              }
              label="Filter unhandled"
            />
          </>
        }
      />
    </div>
  );
};
