import {
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import { NAVBAR_ROUTES } from "components/navigation/components/NavRoutes/constants";

export const PermissionPicker = ({
  onChange,
  value = [],
}: {
  value?: string[];
  onChange: (key: string, value: string[]) => void;
}) => {
  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const valueArray = typeof value === "string" ? value.split(",") : value;
    onChange("deviceTokens", valueArray);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="multiple-checkbox-label">Permission*</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label="Permission" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {NAVBAR_ROUTES.filter(({ isAdmin }) => !isAdmin).map(
            ({ text, route }) => (
              <MenuItem key={route} value={text}>
                <Checkbox checked={value.includes(text)} />
                <ListItemText primary={text} />
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
};
