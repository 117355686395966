import axiosInstance from "../../config/axios";
import ServerRoutes from "./routes";

//Utils
import { getParamsUrl } from "./utils/common";

//Interfaces
import {
  IFetchListResponse,
  FETCH_LIST_RESPONSE_MOCK,
} from "interfaces/api/common";
import {
  IGetAccountParams,
  IGetAccountsParams,
} from "../../interfaces/api/accounts";
import { CmsAccountDto, IAccount } from "interfaces/models";

export const cmsAccountsApi = {
  getAccounts: async (
    params: IGetAccountsParams
  ): Promise<IFetchListResponse<IAccount>> => {
    try {
      const url = getParamsUrl(ServerRoutes.cmsAccounts.accounts, params);
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.error(error);
      return FETCH_LIST_RESPONSE_MOCK;
    }
  },

  getAccountById: async (params: IGetAccountParams): Promise<IAccount> => {
    try {
      let url = `${ServerRoutes.cmsAccounts.getAccount}/?id=${params.id}`;
      const { data } = await axiosInstance.get(url);

      return data;
    } catch (error: any) {
      console.error(error);
      return {} as IAccount;
    }
  },

  createAccount: async (dto: CmsAccountDto): Promise<IAccount> => {
    try {
      let url = `${ServerRoutes.cmsAccounts.createAccount}`;
      const { data } = await axiosInstance.post(url, dto);

      return data;
    } catch (error: any) {
      console.error(error);
      return {} as IAccount;
    }
  },

  updateAccount: async (
    id: string,
    { role, deviceTokens, email, fullName, password, phone }: CmsAccountDto
  ): Promise<boolean> => {
    try {
      let url = `${ServerRoutes.cmsAccounts.updateAccount}/${id}`;
      const { data } = await axiosInstance.patch(url, {
        role,
        deviceTokens,
        email,
        fullName,
        password,
        phone,
      });

      return data;
    } catch (error: any) {
      console.error(error);
      return false;
    }
  },
};
