import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";

import "./styles/index.css";
import App from "./App";
import store from "./services/config";
import Theme from "./theme/theme";
import ToastProvider from "./components/Notifications/ToastProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={Theme}>
    <FluentProvider theme={teamsLightTheme} style={{ height: "100%" }}>
      <Provider store={store}>
        <ToastProvider />
        <App />
      </Provider>
    </FluentProvider>
  </ThemeProvider>
);
