import { Tune } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { FilterDrawer } from "components/filterDrawer";
import LoaderContainer from "components/ui/loaders/LoaderContainer";
import { useAsync, useFilterDrawer } from "hooks";
import { reportsApi } from "services/api/reports-api";
import { Chart, Root } from "./BreathingSessionCount.style";

export const BreathingSessionCount = () => {
  const { filterOpen, filters, setFilterOpen, setFilters } = useFilterDrawer();

  const { value, status } = useAsync(
    () => reportsApi.getBreathingCycleCount(filters),
    [filters]
  );

  return (
    <>
      <Root>
        <Fab onClick={() => setFilterOpen(true)} variant="extended">
          <Tune sx={{ mr: 1 }} />
          Filters
        </Fab>

        {status !== "pending" ? (
          <Chart dangerouslySetInnerHTML={{ __html: value || "" }} />
        ) : (
          <LoaderContainer />
        )}
      </Root>
      <FilterDrawer
        onSubmit={(ages, genders) => setFilters({ ages, genders })}
        open={filterOpen}
        initialValues={filters}
        onClose={() => setFilterOpen(false)}
      />
    </>
  );
};
