import env from "../config";

import { IError } from "./../interfaces/base";
import { IGetPersignedUrlParams } from "../interfaces/api/files";
import filesApi from "../services/api/files-api";

interface IUploadFileResponse {
  data?: {
    url: string;
    key: string;
  };
  error?: IError;
}
export const uploadFile = async (
  file: File,
  getPresignedUrlParams: IGetPersignedUrlParams
): Promise<IUploadFileResponse> => {
  try {
    const presignedUrlRes = await filesApi.getPresignedUrl(
      getPresignedUrlParams
    );

    if (presignedUrlRes.error) {
      throw presignedUrlRes.error;
    }

    const { url, key } = presignedUrlRes.data;

    const uploadRes = await filesApi.uplaodFile({ url, file });

    if (uploadRes.error) {
      throw uploadRes.error;
    }

    return {
      data: {
        url,
        key,
      },
    };
  } catch (error: any) {
    return error;
  }
};

export const getImageSource = (imagePath: string | null) => {
  if (imagePath) {
    const url = `${env.apiGateway.IMAGE_STORAGE_URL}/${imagePath}?alt=media`;
    return url;
  } else {
    return null;
  }
};

export const getFileSource = (filePath: string | null) => {
  if (filePath) {
    const url = `${env.apiGateway.FILES_STORAGE_URL}/${filePath}`;
    return url;
  } else {
    return null;
  }
};

export const getDistributeFileSource = (filePath: string | null) => {
  if (filePath) {
    const url = `${env.apiGateway.DISTRIBUTION_STORAGE_URL}/${filePath}`;
    return url;
  } else {
    return null;
  }
};
