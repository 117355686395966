import ServerRoutes from "./routes";
import { arrayToQueryParam, handleAxiosError } from "./utils/common";
import axiosInstance from "../../config/axios";

const getFiltersUrl = (ages?: number[], genders?: string[]) =>
  `${ages?.length ? `?${arrayToQueryParam("ages", ages)}` : ""}${
    genders?.length
      ? `${ages?.length ? "&" : "?"}${arrayToQueryParam(
          "genders",
          genders.map((gender) => gender.toLowerCase())
        )}`
      : ""
  }`;

export const reportsApi = {
  getGAD7: async ({
    ages,
    genders,
  }: {
    ages?: number[];
    genders?: string[];
  }): Promise<string> => {
    try {
      const { data } = await axiosInstance.get(
        `${ServerRoutes.reports.gad7}${getFiltersUrl(ages, genders)}`
      );

      return data;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return "";
    }
  },
  getBreathingCycleAvg: async ({
    ages,
    genders,
  }: {
    ages?: number[];
    genders?: string[];
  }) => {
    try {
      const { data } = await axiosInstance.get(
        `${ServerRoutes.reports.breathingCycleAvg}${getFiltersUrl(
          ages,
          genders
        )}`
      );

      return data;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return "";
    }
  },
  getBreathingCycleCount: async ({
    ages,
    genders,
  }: {
    ages?: number[];
    genders?: string[];
  }) => {
    try {
      const { data } = await axiosInstance.get(
        `${ServerRoutes.reports.breathingCycleCount}${getFiltersUrl(
          ages,
          genders
        )}`
      );

      return data;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return "";
    }
  },
};
