import { ROUTES_PATHS } from "../../../../../common";

export const NAVBAR_ROUTES = [
  { route: ROUTES_PATHS.Dashboard.library, text: "Library", isAdmin: false },
  { route: ROUTES_PATHS.Dashboard.scenes, text: "Scenes", isAdmin: false },
  { route: ROUTES_PATHS.Dashboard.boxes, text: "Boxes", isAdmin: false },
  { route: ROUTES_PATHS.Dashboard.levels, text: "Levels", isAdmin: false },
  {
    route: ROUTES_PATHS.Dashboard.dailyRewards,
    text: "Daily Rewards",
    isAdmin: false,
  },

  {
    route: ROUTES_PATHS.Dashboard.gameConfig,
    text: "Settings",
    isAdmin: false,
  },
  { route: ROUTES_PATHS.Dashboard.fota, text: "Fota", isAdmin: false },
  {
    route: ROUTES_PATHS.Dashboard.accounts,
    text: "Accounts",
    isAdmin: false,
  },
  {
    route: ROUTES_PATHS.Dashboard.subscription,
    text: "Subscriptions",
    isAdmin: false,
  },
  {
    route: ROUTES_PATHS.Dashboard.orders,
    text: "B2B Orders",
    isAdmin: false,
  },
  {
    route: ROUTES_PATHS.Dashboard.reports,
    text: "Reports",
    isAdmin: false,
  },
  {
    route: ROUTES_PATHS.Dashboard.manageAccount,
    text: "Manage Accounts",
    isAdmin: true,
  },
];
