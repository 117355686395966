//Style
import { useIconsStyles } from "../../hooks/useStyles";
import { LargeTitle } from "@fluentui/react-components";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";

//Common
import { ROUTES_PATHS } from "common";

//Hooks
import { useAsync } from "../../hooks/useAsync";
import { useEffect, useMemo, useState } from "react";

//Interfaces
import { IAccount, CmsAccountDto } from "../../interfaces/models";

//Service
import { cmsAccountsApi } from "../../services/api";

//Utils
import { checkAllCommonParams, getDetails } from "utils";

//Constants
import { CMS_ACCOUNT_FORM_ADD, CMS_ACCOUNT_FORM_UPDATE } from "./constants";

//Components
import { StyledButton } from "styledComponents";
import { Form, PermissionPicker } from "components";
import { FlexColumn, TitleContainer } from "components/common/containers";

export const CmsAccountPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const iconsClasses = useIconsStyles();
  const navigate = useNavigate();

  const search = location.state?.search;

  const isAdd = id === "add-new";

  const { value: account } = useAsync<IAccount>(
    () => cmsAccountsApi.getAccountById({ id: id || "" }),
    [id],
    !isAdd
  );

  const [data, setData] = useState<CmsAccountDto>(
    (isAdd ? {} : account || {}) as CmsAccountDto
  );

  useEffect(() => {
    if (!!account) {
      setData(account);
    }
  }, [account]);

  const handleChange = (key: string, value: any) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const details = getDetails<CmsAccountDto>(
    isAdd ? CMS_ACCOUNT_FORM_ADD : CMS_ACCOUNT_FORM_UPDATE,
    data
  );

  const manageFunction = useMemo(
    () => () =>
      isAdd
        ? cmsAccountsApi.createAccount(data)
        : cmsAccountsApi.updateAccount(id!, data),
    [id, data]
  );

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      <TitleContainer>
        <IosArrowLtr24Filled
          onClick={() => {
            navigate(ROUTES_PATHS.Dashboard.manageAccount, {
              state: { search },
            });
          }}
          className={iconsClasses.loginArrow}
        />
        <LargeTitle>{isAdd && "Create "}CMS Account</LargeTitle>
      </TitleContainer>
      <Form details={details} row={data} onChange={handleChange} />
      <PermissionPicker onChange={handleChange} value={data?.deviceTokens} />

      {(!account || !checkAllCommonParams(data, account)) && (
        <StyledButton
          addBorder
          sx={{ fontSize: "2rem", width: "7rem", marginTop: "2rem" }}
          onClick={() =>
            manageFunction().then(() => {
              navigate(ROUTES_PATHS.Dashboard.manageAccount, {
                state: { search },
              });
            })
          }
        >
          {isAdd ? "Create" : "Update"}
        </StyledButton>
      )}
    </FlexColumn>
  );
};
