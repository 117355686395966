import { Fab, styled, SxProps } from "@mui/material";

export const Root = styled("div")({
  padding: "0 5rem 4rem 5rem",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
});
export const Filters = styled("div")({ width: "100%" });

export const Buttons = styled("div")({
  display: "flex",

  flexDirection: "column",
  alignItems: "center",
});

export const CloseButton = styled(Fab)({
  position: "absolute",
  top: "50%",
  left: "0",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: "unset",
});

export const selectSX: SxProps = {
  height: "3.5rem",
  width: "15rem",
  marginBottom: "2rem",
};
