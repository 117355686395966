import { Option } from "../../../interfaces/components/common/Select";
import { IAccountSortOption } from "../../../interfaces/api/accounts";

export const ACCOUNTS_SORT_OPTIONS: Option<IAccountSortOption>[] = [
  {
    id: "1",
    label: "Created At - Ascending",
    value: { sortBy: "createdAt", sortDir: "asc" },
  },
  {
    id: "2",
    label: "Created At - Descending",
    value: { sortBy: "createdAt", sortDir: "desc" },
  },
  {
    id: "3",
    label: "Name - Ascending",
    value: { sortBy: "fullName", sortDir: "asc" },
  },
  {
    id: "4",
    label: "Name - Descending",
    value: { sortBy: "fullName", sortDir: "desc" },
  },
];
