import { Divider } from "@mui/material";

//Utils
import { twoDigitsNumber } from "../../../../utils";

//Interfaces
import { CustomerEvent } from "interfaces/models";

//Components
import { StyledPopup } from "styledComponents";
import { Container, Data } from "./EventsPopup.style";

export const EventsPopup = ({
  events,
  open,
  onClose,
}: {
  events: CustomerEvent[];
  open?: boolean;
  onClose: () => void;
}) => (
  <StyledPopup open={!!open} onClose={onClose} maxWidth={"md"} title="Timeline">
    <>
      {events.map(({ description, source, created_at }) => {
        const date = new Date(created_at);
        return (
          <>
            <Container>
              <Data>{`${description} by ${source} `}</Data>
              <Data>
                {`${date.toLocaleDateString()} ${twoDigitsNumber(
                  date.getUTCHours()
                )}:${twoDigitsNumber(date.getUTCMinutes())}:${twoDigitsNumber(
                  date.getUTCMinutes()
                )}`}
              </Data>
            </Container>
            <Divider />
          </>
        );
      })}
    </>
  </StyledPopup>
);
