import { FormComponentsProps } from "interfaces";
import FormInput from "../../../ui/inputs/FormInput";
import { ISubscription } from "interfaces/models";

export const ProductName = ({ value }: FormComponentsProps<ISubscription>) => {
  const NAMES: { [x: string]: string } = {
    ["7167828820050"]: "UHG",
    ["7395228745810"]: "UHG Spanish",
    ["7168150601810"]: "Influencer",
    ["7168163577938"]: "HCP",
    ["7168166756434"]: "Beta",
    ["7232067141714"]: "Support",
    ["7412179075154"]: "Giveaway",
  };

  return (
    <FormInput
      placeholder="Product"
      value={value ? `${value} - ${NAMES[value] ?? "unknown"}` : undefined}
      style={{
        marginTop: "5px",
        pointerEvents: "none",
        width: "100%",
      }}
    />
  );
};
