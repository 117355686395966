import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "@mui/icons-material";
import { Alert, IconButton, InputBase, Paper } from "@mui/material";

export const NavigationField = ({
  value,
  link,
  linkValidator,
}: {
  value: any;
  link: string;
  linkValidator: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 1500);
    }
  }, [open]);

  return (
    <>
      {value && open && (
        <Alert
          sx={{
            height: "2.5rem",
            marginTop: "-3.3rem",
            border: "unset",
            fontWeight: 800,
          }}
          icon={false}
          variant="outlined"
          severity="info"
        >
          Copied to clipboard
        </Alert>
      )}

      <Paper
        onClick={() => {
          if (value) {
            setOpen(true);
            navigator.clipboard.writeText(value);
          }
        }}
        component="div"
        sx={{
          boxShadow: "unset",
          marginTop: "0.4rem",
          height: "1.6rem",
          border: "0.1rem solid #CBCBCB",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InputBase sx={{ color: "black" }} value={value || ""} />
        {linkValidator && (
          <IconButton
            onClick={() => linkValidator && navigate(link)}
            type="button"
          >
            <Link />
          </IconButton>
        )}
      </Paper>
    </>
  );
};
