import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { tokens } from "@fluentui/react-components";

import {
  Gad7,
  BreathingSessionCount,
  BreathingSessionChart,
} from "components/";
import { Root } from "./Reports.style";
import {
  Mood,
  LockClock,
  DateRange,
  SupervisedUserCircle,
} from "@mui/icons-material";

export const Reports = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const TABS = [
    { Component: Gad7, label: "GAD7", Icon: Mood },
    {
      Component: BreathingSessionChart,
      label: "AVG USAGES",
      Icon: DateRange,
    },
    {
      Component: BreathingSessionCount,
      label: "USERS BY WEEK",
      Icon: SupervisedUserCircle,
    },
  ];
  const Panel = TABS[selectedTab].Component;

  return (
    <Root>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        sx={{
          borderRight: 1,
          borderColor: "divider",
          width: "14rem",
          "& .MuiTabs-indicator": {
            backgroundColor: tokens.colorBrandForeground1,
          },
          "& .MuiTabs-flexContainer": {
            width: "14rem",
            alignItems: "flex-start",
          },
        }}
      >
        {TABS.map(({ label, Icon }, index) => (
          <Tab
            sx={{
              "&.Mui-selected": {
                color: tokens.colorBrandForeground1,
              },
              "&.Mui-focusVisible": {
                backgroundColor: tokens.colorBrandForeground1,
              },
            }}
            value={index}
            label={label}
            icon={<Icon />}
            iconPosition="start"
          />
        ))}
        <Tab
          value={TABS.length}
          icon={<LockClock />}
          iconPosition="start"
          label="coming soon..."
          disabled
        />
      </Tabs>

      <Panel />
    </Root>
  );
};
