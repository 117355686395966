import { IWithPagination, IWithSort } from "./common";
import { Option } from "../components/common/Select";
import { IOtherLevelRewardTypeOptions } from "../models/level";

export type LevelsSort = "level" | "createdAt";

export interface IGetLevelsParams
  extends IWithPagination,
    IWithSort<LevelsSort> {
  search?: string;
}

export interface IGetLevelParams {
  levelRewardId: string;
}

export interface ILevelsSortOption extends IWithSort<LevelsSort> {}

export const levelsSortOptions: Option<ILevelsSortOption>[] = [
  {
    id: "1",
    label: "Created At - Ascending",
    value: { sortBy: "createdAt", sortDir: "asc" },
  },
  {
    id: "2",
    label: "Created At - Descending",
    value: { sortBy: "createdAt", sortDir: "desc" },
  },
  {
    id: "3",
    label: "Level - Ascending",
    value: { sortBy: "level", sortDir: "asc" },
  },
  {
    id: "4",
    label: "Level - Descending",
    value: { sortBy: "level", sortDir: "desc" },
  },
];

export interface ICreateLevelBody {
  level: number;
  coins: number;
  other: IOtherLevelRewardTypeOptions;
  otherAmount: number;
}

export interface IUpdateLevelBody {
  id: string;
  data: {
    level: number;
    coins: number;
    other: IOtherLevelRewardTypeOptions | undefined;
    otherAmount: number | undefined;
  };
}
