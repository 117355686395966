import { useState } from "react";

export const useToggle = (initialState: boolean) => {
  const [value, setValue] = useState(initialState);

  const toggleValue = () => {
    setValue(!value);
  };

  return { value, setValue, toggleValue };
};
