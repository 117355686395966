import { FlexRow } from "components/common/containers";
import { useState } from "react";

//StyledComponents
import { StyledPopup } from "styledComponents";

//Service
import { rechargeApi } from "services/api";

//Components
import TextInput from "../../../../../ui/inputs/FormInput";
import { ActionButton, PopupButton } from "./SubscriptionActions.style";

export const SubscriptionActions = ({
  status,
  id,
  refetch,
}: {
  status: string;
  id: string;
  refetch: () => void;
}) => {
  const [cancelReason, setReason] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <FlexRow>
      {status === "ACTIVE" && (
        <>
          <ActionButton onClick={() => setPopupOpen(true)}>
            cancel subscription
          </ActionButton>
          <StyledPopup
            title={`Cancel subscription ${id}`}
            open={popupOpen}
            maxWidth="xs"
            onClose={() => setPopupOpen(false)}
          >
            <FlexRow>
              <TextInput
                placeholder="Cancellation Reason"
                value={cancelReason}
                onChange={({ target: { value } }) => setReason(value)}
              />
              <PopupButton
                sx={{ marginTop: "-0.5rem" }}
                onClick={() =>
                  rechargeApi.cancelSubscription(id, cancelReason).then(() => {
                    setPopupOpen(false);
                    refetch();
                  })
                }
              >
                Cancel Subscription
              </PopupButton>
            </FlexRow>
          </StyledPopup>
        </>
      )}
      {status === "CANCELLED" && (
        <ActionButton
          onClick={() => rechargeApi.activateSubscription(id).then(refetch)}
        >
          activate subscription
        </ActionButton>
      )}
    </FlexRow>
  );
};
