export const valueText = (value: number) => {
  const labelByValue: { [x: number]: string } = {
    15: "0-17: Children/Teens",
    20: "18-24: Young Adults",
    30: "25-34: Early Career Adults",
    40: "35-44: Mid-Career Adults",
    50: "45-54: Late Career Adults",
    60: "55-64: Pre-retirement",
    70: "65+: Retirement Age",
  };
  return labelByValue[value];
};

export const RANGE_OPTION = [
  { value: 15, label: "0-17: Children/Teens" },
  { value: 20, label: "18-24: Young Adults" },
  { value: 30, label: "25-34: Early Career Adults" },
  { value: 40, label: "35-44: Mid-Career Adults" },
  { value: 50, label: "45-54: Late Career Adults" },
  { value: 60, label: "55-64: Pre-retirement" },
  { value: 70, label: "65+: Retirement Age" },
];

export const valueToRange = (value: number) => {
  const rangeByValue: { [x: number]: [number, number] } = {
    15: [0, 17],
    20: [18, 24],
    30: [25, 34],
    40: [35, 44],
    50: [45, 54],
    60: [55, 64],
    70: [65, 200],
  };
  return rangeByValue[value];
};
