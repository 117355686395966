import { Button, styled } from "@mui/material";
import { fonts } from "theme/theme";

export const Root = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignContent: "flex-start",
  flexWrap: "wrap",
});

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-start",
  flexWrap: "wrap",
});

export const Section = styled("div")({
  display: "flex",
  alignItems: "flex-start",
});

export const CreateButton = styled(Button)(() => ({
  height: "fit-content",
  width: "fit-content",
  color: "#0897a7",
  fontFamily: fonts.fontFamily.bold,
  fontSize: "1.2rem",
  border: "0.2rem solid #045A6B",
  borderRadius: "1rem",
  padding: "1rem",
  marginTop: "3rem",
}));
