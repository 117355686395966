import FormInput from "../ui/inputs/FormInput";
import { dateFormat } from "../../utils";
import { FormComponentsProps } from "interfaces";

export const DateFormatForm = ({ value }: FormComponentsProps<any>) => {
  return (
    <FormInput
      value={
        value ? dateFormat(new Date(value), { withTime: false }) : undefined
      }
      style={{
        marginTop: "5px",
        pointerEvents: "none",
        width: "100%",
      }}
    />
  );
};
