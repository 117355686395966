import { ROUTES_PATHS } from "common";
import { columnFields, Table } from "components";
import { DateAndTime } from "components/dateAndTime";
import { useTableData } from "hooks";
import { IAccount } from "interfaces/models";
import { useLocation, useNavigate } from "react-router-dom";
import { cmsAccountsApi } from "services/api";

const columns: columnFields[] = [
  {
    fieldName: "fullName",
    columnName: "Full Name",
  },
  {
    fieldName: "email",
    columnName: "Email",
  },
  {
    fieldName: "phone",
    columnName: "Phone number",
  },
  {
    fieldName: "role",
    columnName: "Role",
  },
  {
    fieldName: "createdAt",
    columnName: "Created At",
    costumeRenderColumn: DateAndTime,
    type: "date",
  },
  {
    fieldName: "updatedAt",
    columnName: "Updated At",
    costumeRenderColumn: DateAndTime,
    type: "date",
  },
];
export const CmsAccounts = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const LIMIT = 20;

  const onEditClick = (account: IAccount, search: string) => {
    navigate(
      ROUTES_PATHS.CmsAccounts.CmsAccountPage.replace(":id", account._id),
      { state: { search } }
    );
  };

  const onAddNewClick = () => {
    navigate(ROUTES_PATHS.CmsAccounts.CmsAccountPage.replace(":id", "add-new"));
  };

  const { getItems, list, page, status, total } = useTableData(
    LIMIT,
    cmsAccountsApi.getAccounts
  );

  return (
    <Table<IAccount>
      withAddNew
      onAddNewClick={onAddNewClick}
      title={"CMS Accounts"}
      list={list || []}
      columns={columns}
      getItems={getItems}
      limit={LIMIT}
      page={page}
      total={total || 0}
      onEditClick={onEditClick}
      loading={status === "pending"}
      withSearch
      initialSearch={location.state?.search || ""}
    />
  );
};
