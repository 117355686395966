import { Checkbox, FormControlLabel, SxProps } from "@mui/material";

export const CheckBox = ({
  checked,
  onChange,
  label,
  disabled,
  sx,
}: {
  label?: string;
  checked?: boolean;
  onChange: (event: React.SyntheticEvent, checked: boolean) => void;
  disabled?: boolean;
  sx?: SxProps;
}) => {
  return (
    <FormControlLabel
      sx={sx}
      control={<Checkbox />}
      label={label}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
  );
};
