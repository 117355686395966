import { styled } from "@mui/material";

export const Data = styled("span")({
  display: "flex",
  alignItems: "flex-start",
  width: "50%",
  flexDirection: "column",
});

export const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  marginBottom: "1rem",
});
export const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "30rem",
});

export const Value = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});
