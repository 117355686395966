import { Button, styled } from "@mui/material";
import { fonts } from "theme/theme";

export const Root = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const OpenPopupButton = styled(Button)(() => ({
  height: "fit-content",
  width: "fit-content",
  color: "#0897a7",
  fontFamily: fonts.fontFamily.bold,
  fontSize: "1.2rem",
}));
