import { IUnique } from "../base";
import { Option } from "../components/common/Select";

export interface Level extends IUnique {
  level: number;
  coins: number;
  other?: IOtherLevelRewardTypeOptions;
  otherAmount?: number;
  updatedAt?: Date;
  createdAt?: Date;
}

export type IOtherLevelRewardTypeOptions = "keySlot" | "key" | "fastDelivery";

export const levelTypeOptions: Option<IOtherLevelRewardTypeOptions>[] = [
  { id: "1", label: "Key Slot", value: "keySlot" },
  { id: "2", label: "Key", value: "key" },
  { id: "3", label: "Fast Delivery", value: "fastDelivery" },
];
