import ServerRoutes from "./routes";
import { handleAxiosError } from "./utils/common";
import axiosInstance from "../../config/axios";
import {
  CreateRechargeDto,
  CustomerEvent,
  SubscriptionData,
} from "interfaces/models/";

export const rechargeApi = {
  getCustomerTimeline: async (customerId: string): Promise<CustomerEvent[]> => {
    try {
      let url = `${ServerRoutes.recharge.getCustomerTimeline}/?customerId=${customerId}`;
      const { data } = await axiosInstance.get(url);

      return data.events;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return [];
    }
  },

  createSubscription: async ({
    first_name,
    last_name,
    email,
    variantId,
    id,
    phone,
    address1: createdAddress,
    city: createdCity,
    country: createdCountry,
    zip: createdZip,
    province: createdProvince,
    country_code: createdCountryCode,
    default_address,
  }: SubscriptionData): Promise<any> => {
    try {
      const { data: value } = await axiosInstance.post(
        ServerRoutes.recharge.manageSubscription,
        {
          firstName: first_name,
          lastName: last_name,
          countryCode: default_address?.country_code || createdCountryCode,
          address1: default_address?.address1 || createdAddress,
          city: default_address?.city || createdCity,
          country: default_address?.country || createdCountry,
          email,
          phone: phone || default_address?.phone,
          zip: default_address?.zip || createdZip,
          province: default_address?.province || createdProvince || " ",
          variantId: `${variantId}`,
          ...(id && { shopifyId: `${id}` }),
        } as CreateRechargeDto
      );

      return value;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return [];
    }
  },

  cancelSubscription: async (
    subscriptionId: string,
    reason: string
  ): Promise<any> => {
    try {
      const { data: value } = await axiosInstance.post(
        `${ServerRoutes.recharge.manageSubscription}/${subscriptionId}/cancel`,
        {
          reason,
        }
      );

      return value;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return;
    }
  },

  activateSubscription: async (subscriptionId: string): Promise<any> => {
    try {
      const { data: value } = await axiosInstance.post(
        `${ServerRoutes.recharge.manageSubscription}/${subscriptionId}/activate`
      );

      return value;
    } catch (error: any) {
      const err = handleAxiosError(error);
      console.error(err.data);
      return;
    }
  },
};
