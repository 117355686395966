export const sliceString = (
  inputString: string,
  startIndex: number,
  endIndex: number
): [string, string, string] => {
  const firstPart = inputString.slice(0, startIndex);

  const secondPart = inputString.slice(startIndex, endIndex + 1);

  const thirdPart = inputString.slice(endIndex + 1);

  return [firstPart, secondPart, thirdPart];
};
