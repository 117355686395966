import React, { FC, useEffect } from "react";
import toast, { Toaster, ToastOptions, ToastPosition } from "react-hot-toast";

import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { cleanNotificationManagerState } from "../../services/features/notifications-manager/notification-manager";
import Alert from "./Alert";

interface IToastProviderProps {
  options?: ToastOptions;
  position?: ToastPosition;
}
const ToastProvider: FC<IToastProviderProps> = ({ options, position }) => {
  const dispatch = useAppDispatch();
  const notificationManager = useAppSelector(
    (state) => state.notificationManager
  );

  useEffect(() => {
    if (notificationManager.notification) {
      toast.custom((t) => (
        <Alert
          toastObject={t}
          alert={
            notificationManager.notification || { message: "", type: "success" }
          }
        />
      ));
      dispatch(cleanNotificationManagerState());
    }
  }, [dispatch, notificationManager]);

  return (
    <Toaster
      position={position || "bottom-left"}
      gutter={25}
      toastOptions={{
        ...options,
        custom: {
          duration: 3000,
        },
      }}
    />
  );
};

export default ToastProvider;
