import styled from "styled-components";

import {
  FlexColumn,
  FlexRowCentered,
} from "../../components/common/containers";

export const Container = styled(FlexColumn)`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 355px;
  position: relative;
`;
export const Spinnercontainer = styled.div`
  align-self: center;
  height: 50px;
`;
export const Buttoncontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0;
  align-items: center;
`;
export const TitleContainer = styled(FlexRowCentered)`
  justify-content: flex-start;
  margin-bottom: 30px;
  position: relative;
`;
