//Interfaces
import { Field } from "interfaces";
import { SubscriptionData } from "../../../../../interfaces/models";

const USER_DETAILS: Field<SubscriptionData>[] = [
  { key: "email", label: "Email" },
  { key: "phone", label: "Phone" },
];

const PERSONAL_DETAILS: Field<SubscriptionData>[] = [
  { key: "first_name", label: "first name" },
  { key: "last_name", label: "last name" },
];

const ADDRESS_DETAILS: Field<SubscriptionData>[] = [
  {
    key: "default_address.country",
    label: "Country",
  },
  {
    key: "default_address.city",
    label: "City",
  },
  {
    key: "default_address.address1",
    label: "Street",
  },
];
const EXTRA_ADDRESS_DETAILS: Field<SubscriptionData>[] = [
  {
    key: "default_address.zip",
    label: "zip",
  },
  {
    key: "default_address.province",
    label: "province",
  },
  {
    key: "default_address.country_code",
    label: "country Code",
  },
];

const CUSTOMER_ADDRESS_DETAILS: Field<SubscriptionData>[] = [
  {
    key: "country",
    label: "Country",
  },
  {
    key: "city",
    label: "City",
  },
  {
    key: "address1",
    label: "Street",
  },
];
const CUSTOMER_EXTRA_ADDRESS_DETAILS: Field<SubscriptionData>[] = [
  {
    key: "zip",
    label: "zip",
  },
  {
    key: "province",
    label: "province",
  },
  {
    key: "country_code",
    label: "country Code",
  },
];

export const CREATE_SUBSCRIPTION_FORM_EXISTING_CUSTOMER = [
  USER_DETAILS,
  PERSONAL_DETAILS,
  ADDRESS_DETAILS,
  EXTRA_ADDRESS_DETAILS,
];

export const CREATE_SUBSCRIPTION_FORM_NON_EXISTING = [
  USER_DETAILS,
  PERSONAL_DETAILS,
  CUSTOMER_ADDRESS_DETAILS,
  CUSTOMER_EXTRA_ADDRESS_DETAILS,
];

export const SEARCH = [
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
  { label: "Order ID", value: "orderID" },
];

export const DEFAULT_VARIANTS: { label: string; value: string }[] = [
  { value: "40827922776146", label: "6 Month Subscription " },
  { value: "40827922808914", label: "12 Month Subscription " },
  { value: "40827922841682", label: "24 Month Subscription " },
];
