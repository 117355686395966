import { IMetadata } from "./../base";

export interface IWithPagination {
  page?: number;
  skip?: number; //not necessary, should check
  limit?: number;
}

export interface IWithSort<T> {
  sortBy?: T;
  sortDir?: "asc" | "desc";
}

export interface IFetchListResponse<T> {
  data: T[];
  metadata: IMetadata;
}

export interface Sort {
  id: string;
  label: string;
  value: {
    sortBy: string;
    sortDir: string;
  };
}

export const FETCH_LIST_RESPONSE_MOCK = {
  data: [],
  metadata: { page: 1, total: 0 },
};
