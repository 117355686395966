export const twoDigits = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  }
  return number.toString();
};

export const DateAndTime = ({ value }: { value: any; row: any }) => {
  const date = value ? new Date(value) : undefined;
  return date ? (
    <div>
      <span>{date.toLocaleDateString()}</span>{" "}
      <span>{`${twoDigits(date.getUTCHours())}:${twoDigits(
        date.getUTCMinutes()
      )}:${twoDigits(date.getUTCMinutes())}`}</span>
    </div>
  ) : (
    <></>
  );
};
