import { useState } from "react";

export const useFilterDrawer = (initialValues?: {
  age: [number, number];
  genders?: string[];
}) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState<{
    ages?: number[];
    genders?: string[];
  }>({
    ages: initialValues?.age || [],
    genders: initialValues?.genders || [],
  });

  return { filterOpen, setFilterOpen, filters, setFilters };
};
