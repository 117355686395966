import Table, { columnFields } from "../table/Table";

import { fetchAccountsStart } from "../../services/features/accounts/accounts-slice";
import { cleanEditAccountState } from "../../services/features/edit-account/edit-account-slice";

//Constants
import { ACCOUNTS_SORT_OPTIONS } from "./constants";
import { ROUTES_PATHS } from "../../common/constants";

//Hooks
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

//Interface
import { IGetAccountsParams } from "../../interfaces/api/accounts";
import { IAccount } from "../../interfaces/models/account";

//Components
import { DateAndTime } from "components/dateAndTime";

const columns: columnFields[] = [
  {
    fieldName: "fullName",
    columnName: "Full Name",
  },
  {
    fieldName: "email",
    columnName: "Email",
  },
  {
    fieldName: "phone",
    columnName: "Phone number",
  },
  {
    fieldName: "activationCode",
    columnName: "Activation Code",
  },
  {
    fieldName: "createdAt",
    columnName: "Created At",
    costumeRenderColumn: DateAndTime,
    type: "date",
  },
];

export const Accounts = () => {
  const {
    accounts,
    loading,
    metadata: { page, total },
  } = useAppSelector((state) => state.accounts);

  const limit = 100;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedSortOptions, setSelectedSortOptions] = useState<any[]>([]);
  const [sortValue, setSortValue] = useState<any>("");

  const onEditClick = (account: IAccount, search: string) => {
    navigate(ROUTES_PATHS.Account.accountPage.replace(":id", account._id), {
      state: { search },
    });
  };

  useEffect(() => {
    dispatch(cleanEditAccountState());
  }, []);

  const getAccounts = useCallback(
    (page: number, search?: string) => {
      const { sortBy, sortDir } = ACCOUNTS_SORT_OPTIONS.find(
        (item) => item.id === selectedSortOptions[0]
      )?.value || { sortBy: "createdAt", sortDir: "desc" }; //In case of undefine

      const params: IGetAccountsParams = {
        sortBy,
        sortDir,
        skip: (page - 1) * limit,
        limit,
        page: page,
        ...(search && search.trim().length > 0 && { search }),
      };

      dispatch(fetchAccountsStart({ params }));
    },
    [selectedSortOptions]
  );

  return (
    <Table<IAccount>
      title="Accounts"
      list={accounts}
      columns={columns}
      getItems={getAccounts}
      limit={limit}
      page={page}
      total={total}
      onEditClick={onEditClick}
      loading={loading}
      sortOptions={ACCOUNTS_SORT_OPTIONS}
      selectedSortOptions={selectedSortOptions}
      setSelectedSortOptions={setSelectedSortOptions}
      sortValue={sortValue}
      setSortValue={setSortValue}
      withSearch
      initialSearch={location.state?.search || ""}
    />
  );
};
