import { useCallback } from "react";
import { FlexColumn } from "../../common/containers";
import Table, { columnFields } from "../../table/Table";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATHS } from "../../../common/constants";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { BoxPrize, boxPrizeTypeOptions } from "../../../interfaces/models/box";
import { IGetBoxPrizesParams } from "../../../interfaces/api/boxes";
import { fetchBoxPrizesStart } from "../../../services/features/edit-box/edit-box-slice";

const columns: columnFields[] = [
  {
    fieldName: "name",
    columnName: "Name",
  },
  {
    fieldName: "amount",
    columnName: "Amount",
  },
  {
    fieldName: "prizeType",
    columnName: "Type",
    type: "dropdownVal",
  },
  {
    fieldName: "chance",
    columnName: "Chance (%)",
  },
  {
    fieldName: "timesCanBeWon",
    columnName: "Times Can Be Won",
  },
  {
    fieldName: "imagePath",
    columnName: "Image",
    type: "image",
  },
];

const PrizesTable = (props: {
  isEdit: boolean;
  boxId: string;
  isPublished: boolean;
}) => {
  const boxPrizes = useAppSelector((state) => state.editBox.boxPrizes);
  const loading = useAppSelector((state) => state.editBox.loading);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onEditClick = (prize: BoxPrize) => {
    navigate(ROUTES_PATHS.Box.boxPrizePage.replace(":id", prize._id));
  };
  const onAddNewClick = () => {
    navigate(ROUTES_PATHS.Box.boxPrizePage.replace(":id", "add-new"));
  };

  const getBoxPrizes = useCallback(() => {
    let params: IGetBoxPrizesParams = {
      giftBoxId: props.boxId,
    };
    if (props.boxId) dispatch(fetchBoxPrizesStart({ params }));
  }, []);

  return (
    <FlexColumn>
      <Table<BoxPrize>
        list={boxPrizes}
        limit={100}
        columns={columns}
        title={"Prizes"}
        getItems={getBoxPrizes}
        total={boxPrizes?.length}
        onEditClick={onEditClick}
        loading={loading}
        withAddNew
        onAddNewClick={onAddNewClick}
        disabledAddNew={!props.isEdit || props.isPublished}
        withSearch={false}
        dropdownOptions={{ prizeType: boxPrizeTypeOptions }}
        withoutEditItems={props.isPublished}
      />
    </FlexColumn>
  );
};

export default PrizesTable;
