import { useState } from "react";

//Constants
import {
  SEARCH,
  DEFAULT_VARIANTS,
  CREATE_SUBSCRIPTION_FORM_NON_EXISTING,
  CREATE_SUBSCRIPTION_FORM_EXISTING_CUSTOMER,
} from "./constants";

//Components
import { LookFor } from "../lookFor";
import { Form, InputPicker, CheckBoxGroup } from "components";

//Style
import {
  Root,
  Section,
  Container,
  CreateButton,
} from "./CreateSubscription.style";

//Interface
import { SubscriptionData } from "interfaces/models";

//Utils
import { getDetails } from "utils";

//Services
import { rechargeApi } from "services/api";

export const CreateSubscription = () => {
  const [data, setData] = useState<SubscriptionData>({} as SubscriptionData);
  const [searchBy, setSearchBy] = useState({ param: "", value: "" });
  const [selectedOrder, setSelectedOrder] = useState("");
  const [isExist, setIsExist] = useState(false);

  const handleChange = (key: string, value: string) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const details = getDetails<SubscriptionData>(
    isExist
      ? CREATE_SUBSCRIPTION_FORM_EXISTING_CUSTOMER
      : CREATE_SUBSCRIPTION_FORM_NON_EXISTING,
    data
  );

  const getSubscriptionsOptions = (
    subscription: SubscriptionData
  ): {
    label: string;
    value: string | undefined;
  }[] => {
    const filteredOrder = subscription.orders
      ?.map(({ lineItems }) =>
        lineItems.filter(({ name }) => name.includes("Subscription"))
      )
      .flat();

    return (
      (filteredOrder?.length
        ? subscription.orders
            ?.map(({ lineItems, name: orderName }) =>
              lineItems
                .filter(({ name }) => name.includes("Subscription"))
                .map(({ name, id }) => ({
                  label: `${orderName} - ${name}`,
                  value: id.split("/").pop(),
                }))
            )
            .flat()
        : DEFAULT_VARIANTS) || DEFAULT_VARIANTS
    );
  };

  return (
    <Root>
      <Container>
        <Section>
          <InputPicker
            options={SEARCH}
            label="Search"
            value={searchBy.value}
            onChange={(param: string, value: string) =>
              setSearchBy({ param, value })
            }
            reset={() => {
              setSearchBy({ param: "", value: "" });
              setIsExist(false);
            }}
          />
          <LookFor
            {...searchBy}
            onDataReturn={(data: any) => {
              setData(data);
              setIsExist(!!Object.values(data).length);
              setSelectedOrder("");
            }}
          />
        </Section>
        <Section>
          <Form
            details={details}
            row={data}
            onChange={isExist ? undefined : handleChange}
          />
        </Section>
      </Container>
      <Container>
        <CheckBoxGroup
          selectedValue={selectedOrder}
          onChange={setSelectedOrder}
          options={getSubscriptionsOptions(data)}
        />
        {selectedOrder !== "" && (
          <CreateButton
            onClick={() =>
              rechargeApi.createSubscription({
                ...data,
                variantId: selectedOrder,
              })
            }
          >
            create
          </CreateButton>
        )}
      </Container>
    </Root>
  );
};
