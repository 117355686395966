import { Field, FieldData } from "interfaces";

export const accessByString = (path: string, object: Record<string, any>) =>
  path.split(".").reduce((o, i) => (o ? o[i] : ""), object);

export const twoDigitsNumber = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  }
  return number.toString();
};

export function getDetails<T>(details: Field<T>[][], row: any) {
  return details.map((detailsKeys) =>
    detailsKeys.reduce(
      (prev, { label, key, required, editable, Components, withCopy }) => [
        ...prev,
        {
          label,
          value: accessByString(key, row),
          required,
          editable,
          key: key,
          Components,
          withCopy,
        },
      ],
      [] as FieldData<T>[]
    )
  );
}

export const checkAllCommonParams = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean => {
  // Get keys that are common to both objects
  const commonKeys = Object.keys(obj1).filter((key) => key in obj2);

  // Check if all common keys have equal values
  for (const key of commonKeys) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};
