import { IWithPagination, IWithSort } from "./common";
import { Option } from "../components/common/Select";

export type LibrarySort = "name" | "createdAt";

export interface IGetLibraryParams
  extends IWithPagination,
    IWithSort<LibrarySort> {
  search?: string;
}

export interface IGetLibraryImageParams {
  fileId: string;
}

export interface ILibrarySortOption extends IWithSort<LibrarySort> {}

export const librarySortOptions: Option<ILibrarySortOption>[] = [
  {
    id: "1",
    label: "Created At - Ascending",
    value: { sortBy: "createdAt", sortDir: "asc" },
  },
  {
    id: "2",
    label: "Created At - Descending",
    value: { sortBy: "createdAt", sortDir: "desc" },
  },
  {
    id: "3",
    label: "Name - Ascending",
    value: { sortBy: "name", sortDir: "asc" },
  },
  {
    id: "4",
    label: "Name - Descending",
    value: { sortBy: "name", sortDir: "desc" },
  },
];
