import { AxiosError } from "axios";
import { ID } from "../../../types/base";

export const handleAxiosError = (error: AxiosError) => {
  const { response } = error;
  return {
    status: response?.status,
    data: response?.data,
  };
};

export const getParamsUrl = (
  baseUrl: string,
  params: { [key: string]: any }
) => {
  let url = baseUrl;
  type keyType = keyof typeof params;

  let keys = Object.keys(params) as keyType[];
  let index = 0;
  for (let key of keys) {
    if (index) {
      url += `&${key}=${encodeURIComponent(params[key])}`;
    } else {
      url += `?${key}=${encodeURIComponent(params[key])}`;
    }
    index++;
  }
  return url;
};

/**
 * Converts an array into a repeated query parameter string.
 *
 * @param key - The query parameter key (e.g., "range").
 * @param values - The array of values to be sent as repeated parameters.
 * @returns A query string like `key=value1&key=value2`.
 */
export const arrayToQueryParam = (key: string, values: any[]): string =>
  values
    .map(
      (value) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    )
    .join("&");

export const replaceUrlId = (path: string, id: ID) => {
  return path.replace(":id", id);
};
