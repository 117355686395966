import { forwardRef, ReactNode } from "react";
import {
  Slide,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  SxProps,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseRounded } from "@mui/icons-material";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const StyledPopup = ({
  children,
  open,
  onClose,
  title,
  maxWidth,
  classes,
}: {
  children: ReactNode;
  open?: boolean;
  onClose: () => void;
  title: string | ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  classes?: { dialog?: SxProps; content?: SxProps };
}) => (
  <Dialog
    sx={classes?.dialog}
    open={!!open}
    fullWidth
    maxWidth={maxWidth}
    scroll="paper"
    TransitionComponent={Transition}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>{title}</DialogTitle>
    <IconButton
      onClick={onClose}
      sx={() => ({
        position: "absolute",
        right: 8,
        top: 8,
      })}
    >
      <CloseRounded />
    </IconButton>
    <DialogContent sx={{ height: "100%", ...classes?.content }}>
      {children}
    </DialogContent>
  </Dialog>
);
