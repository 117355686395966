import React, { useState } from "react";
import { useButtonStyles } from "../../hooks/useStyles";
import { Button } from "@fluentui/react-components";
import Modal from "../common/Modal";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchLibraryStart } from "../../services/features/images-library/library-slice";
import { LibraryImage } from "../../interfaces/models/library-img";
import { IGetLibraryParams } from "../../interfaces/api/library";
import LibraryGrid from "./LibraryGrid";
import usePermission from "../../hooks/usePermission";

const ChooseLibraryImg = (props: {
  onChosenImg: Function;
  btnTitle?: string;
}) => {
  const [showLibraryModal, setShowLibraryModal] = useState(false);
  const [chosenImage, setChosenImage] = useState<LibraryImage | null>(null);
  const writeAndStronger = usePermission("write:>=");
  const buttonClasses = useButtonStyles();

  const onAttachClick = () => {
    setShowLibraryModal(true);
  };

  const chooseImage = () => {
    props.onChosenImg(chosenImage?.gcsName);
    setShowLibraryModal(false);
    setChosenImage(null);
  };

  return (
    <>
      {writeAndStronger ? (
        <Button
          className={buttonClasses.secondary}
          appearance="primary"
          style={{ marginTop: "10px" }}
          onClick={onAttachClick}
        >
          {props.btnTitle ? props.btnTitle : "Attach"}
        </Button>
      ) : null}
      <Modal
        title={"Choose Image"}
        open={showLibraryModal}
        onOpenChange={setShowLibraryModal}
        onActionClick={chooseImage}
        actionTitle={"save"}
        modalContent={() => (
          <LibraryGridWrapper
            chosenImage={chosenImage}
            setChosenImage={setChosenImage}
          />
        )}
        disabledAction={chosenImage?.mediaLink ? false : true}
      />
    </>
  );
};

export default ChooseLibraryImg;

const LibraryGridWrapper = (props: {
  chosenImage: LibraryImage | null;
  setChosenImage: Function;
}) => {
  const library = useAppSelector((state) => state.library.library);
  const loading = useAppSelector((state) => state.library.loading);
  const total = useAppSelector((state) => state.library.metadata.total);
  const page = useAppSelector((state) => state.library.metadata.page);
  const limit = 40;
  const dispatch = useAppDispatch();

  const getImages = (page: number, search?: string) => {
    let params: IGetLibraryParams = {
      sortBy: "createdAt",
      sortDir: "desc",
      skip: (page - 1) * limit,
      limit: limit,
      page: page,
    };

    if (search && search.trim().length > 0) {
      params["search"] = search;
    }
    dispatch(fetchLibraryStart({ params }));
  };

  return (
    <LibraryGrid
      list={library}
      getItems={getImages}
      limit={limit}
      page={page}
      total={total}
      loading={loading}
      chosenImage={props.chosenImage}
      setChosenImage={props.setChosenImage}
    />
  );
};
