import { useState } from "react";
import { Divider, CircularProgress } from "@mui/material";

//Utils
import { twoDigitsNumber } from "../../../../utils";

//Hooks
import { useAsync } from "hooks/useAsync";

//Services
import { devicesApi } from "services/api";

//Components
import {
  Logs,
  Data,
  Root,
  Title,
  DataLast,
  Container,
} from "./DeviceLogsPopup.style";
import DatePicker, { DateObject } from "react-multi-date-picker";

//Utils
import { addDays } from "date-fns";
import { sliceString } from "./utils";
import { StyledPopup } from "styledComponents";

export const DeviceLogsPopup = ({
  deviceId,
  onClose,
  open,
}: {
  open: boolean;
  deviceId: string;
  onClose: () => void;
}) => {
  const [values, setValues] = useState([
    new DateObject(addDays(new Date(), -20)),
    new DateObject(),
  ]);
  const { value: logs, status } = useAsync(
    () =>
      devicesApi.getDeviceTracer(
        deviceId,
        new Date(values[0].year, values[0].month.index, values[0].day),
        new Date(values[1].year, values[1].month.index, values[1].day + 1)
      ),
    [values],
    values.length === 2
  );

  return (
    <StyledPopup
      title={
        <Title>
          {`Device ${deviceId} Logs`}
          <DatePicker
            style={{ width: "10rem" }}
            rangeHover
            range
            value={values}
            onChange={setValues}
          />
        </Title>
      }
      open={!!open}
      maxWidth={"xl"}
      classes={{ dialog: { minHeight: "20rem" } }}
      onClose={onClose}
    >
      <Root>
        {status !== "pending" ? (
          logs?.map(({ createdAt, log }) => {
            const date = new Date(createdAt);
            const [header, type, data] = sliceString(log, 4, 5);
            return (
              <>
                <Container>
                  <Logs>
                    <Data>{`${header} `}</Data>
                    <Data isColor>{`${type} `}</Data>
                    <DataLast>{`${data} `}</DataLast>
                  </Logs>
                  <Data>
                    {`${date.toLocaleDateString()} ${twoDigitsNumber(
                      date.getUTCHours()
                    )}:${twoDigitsNumber(
                      date.getUTCMinutes()
                    )}:${twoDigitsNumber(date.getUTCMinutes())}`}
                  </Data>
                </Container>
                <Divider />
              </>
            );
          })
        ) : (
          <CircularProgress />
        )}
      </Root>
    </StyledPopup>
  );
};
