import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import oldStyled from "styled-components";

import { useId, Textarea, TextareaProps } from "@fluentui/react-components";
import { Field } from "@fluentui/react-field";

import usePermission from "../../../hooks/usePermission";

import {
  Alert,
  styled,
  SxProps,
  TextField,
  TextFieldVariants,
} from "@mui/material";

const FormInputContainer = oldStyled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
`;
const FieldInput = styled(TextField)({
  "& .MuiInputBase-input": {
    height: "0.8rem",
  },
});

interface IFormInputProps {
  errorMessage?: string;
  variant?: TextFieldVariants;
  style?: SxProps;
  permission?: string;
  value?: string;
  placeholder?: string;
  contentAfter?: JSX.Element;
  type?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: ({ key }: KeyboardEvent<HTMLInputElement>) => void;
  withCopy?: boolean;
  ref?: any;
  onClick?: Function;
}

const FormInput = ({
  variant,
  errorMessage,
  style,
  permission,
  value,
  onChange,
  type,
  placeholder,
  contentAfter,
  withCopy = false,
  onKeyDown,
  ref,
  onClick,
}: IFormInputProps) => {
  const [open, setOpen] = useState(false);

  const writeAndStronger = usePermission(permission || "write:>=");
  const inputId = useId("input");

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 1500);
    }
  }, [open]);

  return (
    <>
      {withCopy && open && (
        <Alert
          sx={{
            height: "2.5rem",
            marginTop: "-3.3rem",
            border: "unset",
            fontWeight: 800,
          }}
          icon={false}
          variant="outlined"
          severity="info"
        >
          Copied to clipboard
        </Alert>
      )}
      <FieldInput
        ref={ref}
        onKeyDown={onKeyDown}
        value={value}
        size="small"
        sx={{ height: "0.8rem", ...style }}
        type={type}
        onClick={() => {
          if (withCopy && value) {
            setOpen(true);
            navigator.clipboard.writeText(value);
          }
          onClick && onClick();
        }}
        variant={variant}
        onChange={onChange}
        placeholder={placeholder}
        error={!!errorMessage}
        slotProps={{ input: { endAdornment: contentAfter } }}
        id={inputId}
        disabled={!writeAndStronger}
      />
    </>
  );
};

export default FormInput;

interface IFormTextareaProps extends Omit<TextareaProps, "onChange"> {
  errorMessage?: string;
  varient?: "underline";
  style?: any;
  permission?: string;
  onChange?: (event: any) => void;
}

export const FormTextArea = (props: IFormTextareaProps) => {
  const { errorMessage, style, permission } = props;
  const writeAndStronger = usePermission(permission || "write:>=");

  return (
    <FormInputContainer className="" style={{ ...style }}>
      <Field validationMessage={errorMessage}>
        <Textarea {...props} disabled={!writeAndStronger} />
      </Field>
    </FormInputContainer>
  );
};
