import { useEffect, useState } from "react";
import {
  Button,
  LargeTitle,
  Subtitle2,
  Label,
  tokens,
  Image,
  Dropdown,
  Option,
  useId,
} from "@fluentui/react-components";
import { IosArrowLtr24Filled } from "@fluentui/react-icons";
import { useParams, useNavigate } from "react-router-dom";
import FormInput from "../../ui/inputs/FormInput";
import LoaderContainer from "../../ui/loaders/LoaderContainer";
import { useForm } from "../../../hooks/useForm";
import { useButtonStyles, useIconsStyles } from "../../../hooks/useStyles";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { BoxPrize, boxPrizeTypeOptions } from "../../../interfaces/models/box";
import {
  ICreateBoxPrizeBody,
  IUpdateBoxPrizeBody,
} from "../../../interfaces/api/boxes";
import { ROUTES_PATHS } from "../../../common/constants";
import AlertPopup from "../../common/AlertPopup";
import {
  FormContainer,
  FormInputContainer,
  TitleContainer,
  FlexColumn,
  FlexRowSpaced,
  FlexRowWrapped,
} from "../../common/containers";
import ErrorMsg from "../../common/ErrorMsg";
import ChooseLibraryImg from "../../choose-library-img/ChooseLibraryImg";
import { getImageSource } from "../../../utils/files-utils";
import {
  cleanEditBoxPrizeState,
  createBoxPrizeStart,
  deleteBoxPrizeStart,
  getBoxPrizeStart,
  updateBoxPrizeStart,
} from "../../../services/features/edit-box-prize/edit-box-prize-slice";
import {
  getInitialFormValues,
  getValidationsObj,
} from "../../../utils/forms-utils";
import usePermission from "../../../hooks/usePermission";

const BoxPrizePage = (props: { metadata: any }) => {
  const { id } = useParams();
  const currPrize = useAppSelector((state) => state.editBoxPrize.prize);
  const currBox = useAppSelector((state) => state.editBox.box);
  const { operation, requestState, loading } = useAppSelector(
    (state) => state.editBoxPrize
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const iconsClasses = useIconsStyles();
  const buttonClasses = useButtonStyles();
  const writeAndStronger = usePermission("write:>=");

  useEffect(() => {
    if (id !== "add-new")
      dispatch(getBoxPrizeStart({ giftBoxPrizeId: id || "" }));
    return () => {
      dispatch(cleanEditBoxPrizeState());
    };
  }, []);

  useEffect(() => {
    if (
      requestState === "finish" &&
      ["delete", "update", "create"].includes(operation || "")
    ) {
      navigate(ROUTES_PATHS.Box.boxPage.replace(":id", currBox?._id || ""));
    }
  }, [operation, requestState]);

  const onDeleteClick = () => {
    setShowDeleteModal(false);
    dispatch(
      deleteBoxPrizeStart({
        giftBoxPrizeId: id || "",
        giftBoxId: currPrize?.giftBoxId || "",
      })
    );
  };

  const openDeletePopup = () => {
    setShowDeleteModal(true);
  };

  return (
    <FlexColumn style={{ padding: " 0 3rem" }}>
      {loading ? <LoaderContainer /> : null}
      <AlertPopup
        title="Are you sure you want to delete?"
        open={showDeleteModal}
        onOpenChange={setShowDeleteModal}
        onActionClick={onDeleteClick}
      />
      <FlexRowSpaced>
        <TitleContainer>
          <IosArrowLtr24Filled
            onClick={() => {
              navigate(
                ROUTES_PATHS.Box.boxPage.replace(":id", currBox?._id || "")
              );
            }}
            className={iconsClasses.loginArrow}
          />
          <LargeTitle>Box Prize</LargeTitle>
        </TitleContainer>
        {id !== "add-new" && !currBox?.isPublished && writeAndStronger ? (
          <Button
            appearance="primary"
            style={{ height: "40px" }}
            className={buttonClasses.delete}
            onClick={openDeletePopup}
          >
            Delete
          </Button>
        ) : null}
      </FlexRowSpaced>
      {id === "add-new" ? (
        <BoxPrizePageForm
          isEdit={false}
          prize={null}
          metadata={props.metadata.createForm}
        />
      ) : currPrize ? (
        <BoxPrizePageForm
          isEdit={true}
          prize={currPrize}
          metadata={props.metadata.editForm}
        />
      ) : null}
    </FlexColumn>
  );
};

const BoxPrizePageForm = (props: {
  prize: any;
  metadata: any;
  isEdit: boolean;
}) => {
  const { prize, metadata, isEdit } = props;

  const loading = useAppSelector((state) => state.editBoxPrize.loading);
  const currBox = useAppSelector((state) => state.editBox.box);
  const [selectedSortOptions, setSelectedSortOptions] = useState<any[]>([]);
  const [dropdownValue, setDropdownValue] = useState(
    boxPrizeTypeOptions.find((item) => item.value === prize?.prizeType)
      ?.label || ""
  );

  const dispatch = useAppDispatch();
  const buttonClasses = useButtonStyles();
  const dropdownId = useId("dropdown");
  const writeAndStronger = usePermission("write:>=");

  const onEditSubmit = (data: BoxPrize) => {
    if (isEdit) {
      const params: IUpdateBoxPrizeBody = {
        id: data._id,
        data: {
          giftBoxId: data.giftBoxId,
          name: data.name,
          amount: data.amount,
          prizeType: data.prizeType,
          imagePath: data.imagePath,
          chance: data.chance,
          timesCanBeWon: data.timesCanBeWon,
        },
      };

      dispatch(updateBoxPrizeStart(params));
    } else if (currBox) {
      dispatch(
        createBoxPrizeStart({
          giftBoxId: currBox._id,
          prize: {
            giftBoxId: currBox._id,
            ...editBoxPrize,
          },
        } as ICreateBoxPrizeBody)
      );
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleCustomChange,
    data: editBoxPrize,
    errors,
  } = useForm<typeof prize>({
    initialValues: getInitialFormValues(prize),
    validations: getValidationsObj(metadata),
    onSubmit: () => {
      if (onEditSubmit) {
        onEditSubmit(editBoxPrize);
      }
    },
  });

  const onEditClick = () => {
    handleSubmit();
  };

  const onOptionSelect = (ev: any, data: any, key: string) => {
    const typeVal = boxPrizeTypeOptions.find(
      (prize) => prize.id === data.selectedOptions[0]
    )?.value;
    handleCustomChange(key, typeVal || "");
    setSelectedSortOptions(data.selectedOptions);
    setDropdownValue(data.optionText ?? "");
  };

  return (
    <FlexColumn>
      <FormContainer style={{ opacity: loading ? "0.3" : "1" }}>
        {metadata != null ? (
          <>
            <FlexRowWrapped style={{ gap: "40px" }}>
              {Object.keys(metadata).map((key, index) => {
                return metadata[key] && metadata[key].type !== "image" ? (
                  <FormInputContainer key={index}>
                    <Label>
                      {metadata[key].fieldName}:
                      {metadata[key].required ? (
                        <>
                          &nbsp;{" "}
                          <Subtitle2
                            style={{
                              color: tokens.colorPaletteCranberryBorderActive,
                            }}
                          >
                            *
                          </Subtitle2>{" "}
                        </>
                      ) : null}
                    </Label>
                    {metadata[key].type === "dropdown" ? (
                      <>
                        <Dropdown
                          disabled={!writeAndStronger}
                          aria-labelledby={`${dropdownId}-underline`}
                          placeholder={metadata[key].fieldName}
                          value={dropdownValue}
                          selectedOptions={selectedSortOptions}
                          onOptionSelect={(ev, data) =>
                            onOptionSelect(ev, data, key)
                          }
                          style={{
                            position: "relative",
                            zIndex: "1",
                            marginTop: "5px",
                            pointerEvents:
                              metadata[key].editable || !prize
                                ? "unset"
                                : "none",
                          }}
                        >
                          {boxPrizeTypeOptions.map((option, index) => {
                            return (
                              <Option key={option.id} value={option.id}>
                                {option.label}
                              </Option>
                            );
                          })}
                        </Dropdown>
                        {errors[key] ? (
                          <ErrorMsg
                            message={errors[key] || ""}
                            withIcon={true}
                          />
                        ) : null}
                      </>
                    ) : (
                      <FormInput
                        value={editBoxPrize[key] || ""}
                        style={{
                          marginTop: "5px",
                          pointerEvents:
                            metadata[key].editable || !prize ? "unset" : "none",
                        }}
                        placeholder={metadata[key].fieldName}
                        onChange={handleChange(key)}
                        errorMessage={errors[key]}
                        type={metadata[key].type || "string"}
                      />
                    )}
                  </FormInputContainer>
                ) : null;
              })}
            </FlexRowWrapped>
            <FlexRowWrapped>
              {Object.keys(metadata).map((key, index) => {
                return metadata[key] && metadata[key].type === "image" ? (
                  <FormInputContainer style={{ width: "unset" }} key={index}>
                    <Label>
                      {metadata[key].fieldName}:
                      {metadata[key].required ? (
                        <>
                          &nbsp;{" "}
                          <Subtitle2
                            style={{
                              color: tokens.colorPaletteCranberryBorderActive,
                            }}
                          >
                            *
                          </Subtitle2>{" "}
                        </>
                      ) : null}
                    </Label>
                    {!metadata[key].editable || editBoxPrize[key] ? (
                      <>
                        <Image
                          width={150}
                          src={getImageSource(editBoxPrize[key]) || undefined}
                        />
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                          btnTitle={"Change Image"}
                        />
                      </>
                    ) : (
                      <>
                        <ChooseLibraryImg
                          onChosenImg={(img: string) =>
                            handleCustomChange(key, img)
                          }
                        />
                        {errors[key] ? (
                          <ErrorMsg
                            message={errors[key] || ""}
                            withIcon={true}
                          />
                        ) : null}
                      </>
                    )}
                  </FormInputContainer>
                ) : null;
              })}
            </FlexRowWrapped>
          </>
        ) : null}
      </FormContainer>
      {writeAndStronger ? (
        <Button
          className={buttonClasses.primary}
          appearance="primary"
          style={{ width: "250px", margin: "20px 0" }}
          onClick={onEditClick}
        >
          Save
        </Button>
      ) : null}
    </FlexColumn>
  );
};

export default BoxPrizePage;
