import {
  NavBar,
  NavItem,
  NavBarContainer,
  NavStyledNavLink,
} from "../../StyledComponents";
import { Text } from "@fluentui/react-components";

//Constants
import { NAVBAR_ROUTES } from "./constants";

//Hooks
import { useSelector } from "react-redux";
import { RootState } from "services/rootReducer";
import usePermission from "../../../../hooks/usePermission";

export const NavRoutes = () => {
  const adminAndStronger = usePermission("admin:>=");

  const auth = useSelector((state: RootState) => state.auth);

  const permission: string[] = auth.user.permission;

  const isAllow = (isAdmin: boolean, text: string) =>
    adminAndStronger || (!isAdmin && permission.includes(text));
  return (
    <NavBarContainer>
      <NavBar>
        {NAVBAR_ROUTES.map(({ isAdmin, route, text }, index) => (
          <span key={index}>
            {isAllow(isAdmin, text) && (
              <NavItem key={index}>
                <NavStyledNavLink to={route}>
                  <Text>{text}</Text>
                </NavStyledNavLink>
              </NavItem>
            )}
          </span>
        ))}
      </NavBar>
    </NavBarContainer>
  );
};
