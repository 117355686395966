const AuthRoutes = {
  login: "/login",
  logout: "/logout",
  resetPassword: "/resetPassword",
};

export const DashboardRoutes = {
  home: "/",
  accounts: "/accounts",
  subscription: "/subscription",
  library: "/library",
  scenes: "/scenes",
  boxes: "/boxes",
  levels: "/levels",
  dailyRewards: "/dailyRewards",
  gameConfig: "/settings",
  fota: "/fota",
  orders: "/orders",
  reports: "/reports",
  manageAccount: "/manageAccount",
};

const AccountRoutes = {
  accountPage: "/accounts/:id",
};
const SubscriptionRoutes = {
  subscriptionPage: "/subscription/:id",
};

const ManageAccountsRoutes = {
  CmsAccountPage: "/manageAccount/:id",
};

const LibraryRoutes = {
  imagePage: "/library/:id",
};

const SceneRoutes = {
  scenePage: "/scenes/:id",
  sceneItemPage: "/scenes/sceneItem/:id",
};

const BoxRoutes = {
  boxPage: "/boxes/:id",
  boxPrizePage: "/boxes/boxPrize/:id",
};
const LevelRoutes = {
  levelPage: "/levels/:id",
};
const DailyRewardRoutes = {
  dailyRewardPage: "/dailyRewards/:id",
};

export const ROUTES_PATHS = {
  Auth: AuthRoutes,
  Dashboard: DashboardRoutes,
  Account: AccountRoutes,
  Subscription: SubscriptionRoutes,
  CmsAccounts: ManageAccountsRoutes,
  Library: LibraryRoutes,
  Scene: SceneRoutes,
  Box: BoxRoutes,
  Level: LevelRoutes,
  DailyReward: DailyRewardRoutes,
};
