import logo from "../../assets/images/logo.png";
import { FullPage } from "../../components/common/containers";
import LoginForm from "./LoginForm";
import { Image } from "@fluentui/react-components";

export const Login = () => {
  return (
    <FullPage style={{ minHeight: "100vh" }}>
      <LoginForm />
      <Image alt="logo" src={logo} width={357} height={357} />
    </FullPage>
  );
};
